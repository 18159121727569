.btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 40rem;
    border-radius: 12rem;
    white-space: nowrap;
    background: #fff;
    color: var(--color-orange);
    border: max(1rem, 1px) solid var(--color-orange);
    box-sizing: border-box;
    padding: 8rem 11rem;
    font-size: 14rem;
    font-variation-settings: 'wght' 600;
    transition: all 0.25s ease;
    user-select: none;
    cursor: pointer;

    html.desktop &:hover {
        background: var(--color-orange);
        color: #fff;
    }

    &:disabled,
    &._is-disabled {
        opacity: 1;
        color: var(--color-grey);
        border-color: var(--color-grey);
        pointer-events: none;
    }

    span {
        display: block;
    }

    i {
        width: 24rem;
        height: 24rem;
        display: block;
        margin-left: 6rem;
        margin-right: -1rem;
        flex-shrink: 0;

        svg {
            display: block;
            width: 100%;
            height: 100%;
        }
    }
}

.btn--filled {
    background: var(--color-orange);
    color: #fff;

    html.desktop &:hover {
        background: var(--color-dark);
        border-color: var(--color-dark);
    }
}

.btn--black {
    color: var(--color-dark);
    border-color: var(--color-dark);

    html.desktop &:hover {
        background: var(--color-dark);
        color: #fff;
    }
}

.btn--invert {
    color: #fff;
    border-color: #fff;
    background: none;

    html.desktop &:hover {
        color: var(--color-orange);
        border-color: var(--color-orange);
        background: none;
    }
}

.btn--small {
    height: 30rem;
    padding: 4rem 10rem;
    font-size: 12rem;
}

.btn--large {
    height: 50rem;
    padding: 0 16rem;
}
