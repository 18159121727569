.wrap {
    @include wrap();
}

.wrap-page {
    overflow: hidden;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    padding-top: 76rem;
    box-sizing: border-box;
}

.content {
    margin-bottom: 100rem;

    @media (max-width: $screen-m) {
        margin-bottom: 80rem;
    }

    @media (max-width: $screen-s) {
        margin-bottom: 60rem;
    }
}

.content__inner {
    @include wrap();
}

.columns {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 0 16rem;

    @media (max-width: $screen-m) {
        gap: 0 12rem
    }
}

.grid {
    @include grid();
}

.grey-block {
    background: var(--color-platina);
    border-radius: 20rem;
}

.grey-block__inner {
    @include wrap;
}

.center {
    text-align: center;
}
