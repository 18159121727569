.breadcrumbs {
    margin: 20rem 0 30rem 0;
    @include wrap();

    @media (max-width: $screen-m) {
        width: 100vw;
        position: relative;
        left: 50%;
        margin-left: -50vw;
        box-sizing: border-box;
    }
}

.breadcrumbs__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 5rem 12rem;
    font-size: 12rem;
    color: var(--color-grey);
    
    @media (max-width: $screen-m) {
        width: 100%;
        box-sizing: border-box;
        padding: 0 4vw;
        flex-wrap: nowrap;
        white-space: nowrap;
        overflow-y: hidden;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        scroll-padding: 0 4vw;

        &::-webkit-scrollbar {
            height: 0;
        }
    }

    @media (max-width: $screen-s) {
        padding: 0 6vw;
        scroll-padding: 0 6vw;
    }

    li {
        display: block;
        scroll-snap-align: start;

        &:not(:last-child) {
            padding-right: 14rem;
            position: relative;

            &:after {
                content: '|';
                position: absolute;
                right: 0;
                top: 0;
                padding: 4rem 0;
            }
        }
    }

    a {
        display: block;
        padding: 4rem 0;
        color: var(--color-grey);
        text-decoration: underline;

        html.desktop &:hover {
            color: var(--color-orange);
        }
    }
}
