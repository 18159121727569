.players__head {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20rem;
    font-variation-settings: 'wght' 300;
    text-align: left;

    @media (min-width: $screen-l) {
        font-size: 16rem;
    }

    @media (max-width: $screen-s) {
        font-size: 10rem;
        margin-bottom: 6rem;
    }

    > div {
        flex-shrink: 0;
        box-sizing: border-box;
        padding-right: 12rem;

        @media (max-width: $screen-s) {
            padding-right: 10rem;
        }

        &:nth-child(1) {
            width: 30%;
            padding-left: 120rem;
    
            @media (max-width: $screen-m) {
                padding-left: 76rem;
                width: 42%;
            }
    
            @media (max-width: $screen-s) {
                padding-left: 46rem;
                width: 60%;
            }
        }
    
        &:nth-child(2) {
            width: 14%;
    
            @media (max-width: $screen-m) {
                width: 16%;
            }
    
            @media (max-width: $screen-s) {
                width: 20%;
            }
        }
    
        &:nth-child(3) {
            width: 14%;
    
            @media (max-width: $screen-m) {
                width: 16%;
            }
    
            @media (max-width: $screen-s) {
                width: 20%;
            }
        }
    
        &:nth-child(4) {
            width: 30%;
    
            @media (max-width: $screen-m) {
                display: none;
            }
        }
    
        &:nth-child(5) {
            width: 12%;
            text-align: right;
            padding-right: 30rem;
    
            @media (max-width: $screen-m) {
                width: 26%;
                padding-right: 20rem;
            }
    
            @media (max-width: $screen-s) {
                width: 100%;
                padding-left: 12rem;
                padding-right: 12rem;
    
                .btn {
                    width: 100%;
                }
            }
        }
    }
}

.players__body {
    @extend .rating__body;
}
