.load-more {
    display: inline-flex;
    align-items: center;
    gap: 10rem;
    height: 48rem;
    font-size: 14rem;
    font-variation-settings: 'wght' 500;
    border: 0;
    background: none;
    transition: color 0.2s ease;
    color: var(--color-dark);

    html.desktop &:hover {
        color: var(--color-orange);
    }

    svg {
        width: 24rem;
        height: 24rem;
    }
}
