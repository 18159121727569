.participants {

    .text-content {
        margin-bottom: 30rem;

        @media (max-width: $screen-s) {
            margin-bottom: 10rem;
        }
    }
}

.participants__filter {
    margin: 30rem 0;

    .filter__inner {
        display: flex;
        justify-content: space-between;

        @media (max-width: $screen-s) {
            margin-bottom: 20rem;
        }
    }

    .filter__inner-right {
    
        @media (max-width: $screen-m) {
            width: auto;
    
            .select {
                width: auto;
            }
        }
    
        @media (max-width: $screen-s) {
            flex-wrap: wrap;
            width: 100%;
    
            .select {
                width: 100%;
            }
        }
    }
}

.participants__head {
    display: flex;
    margin-bottom: 20rem;
    font-variation-settings: 'wght' 300;
    text-align: left;

    @media (min-width: $screen-l) {
        font-size: 16rem;
    }

    @media (max-width: $screen-s) {
        font-size: 10rem;
        margin-bottom: 6rem;
    }

    > div {
        flex-shrink: 0;
        box-sizing: border-box;
        padding-right: 12rem;

        @media (max-width: $screen-s) {
            padding-right: 10rem;
        }

        &:nth-child(1) {
            display: none;
        }
    
        &:nth-child(2) {
            width: 33%;
            padding-left: 121rem;
    
            @media (max-width: $screen-m) {
                padding-left: 78rem;
                width: 38%;
            }
    
            @media (max-width: $screen-s) {
                padding-left: 46rem;
                width: 55%;
            }
        }
    
        &:nth-child(3) {
            width: 14%;
    
            @media (max-width: $screen-m) {
                width: 24%;
            }
    
            @media (max-width: $screen-s) {
                width: 25%;
            }
        }
    
        &:nth-child(4) {
            width: 17%;
    
            @media (max-width: $screen-m) {
                width: 15%;
            }
    
            @media (max-width: $screen-s) {
                width: 20%;
            }
        }
    
        &:nth-child(5) {
            width: 20%;
    
            @media (max-width: $screen-m) {
                display: none;
            }
        }
    
        &:nth-child(6) {
            width: 16%;
            padding-right: 0;
    
            @media (max-width: $screen-m) {
                width: 23%;
            }
    
            @media (max-width: $screen-s) {
                display: none;
            }
        }
    }
}

.participants__body {
    @extend .rating__body;
}

.participants--results {

    .participants__head {

        > div {

            &:nth-child(1) {
                display: block;
                width: 7%;
                padding-left: 30rem;
                padding-right: 0;
    
                @media (max-width: $screen-m) {
                    width: 10%;
                    padding-left: 20rem;
                }
    
                @media (max-width: $screen-s) {
                    padding-left: 12rem;
                    width: 13%;
                }
            }
    
            &:nth-child(2) {
                width: 40%;
                padding-left: 93rem;
    
                @media (max-width: $screen-m) {
                    width: 50%;
                    padding-left: 59rem;
                }
        
                @media (max-width: $screen-s) {
                    width: 50%;
                    padding-left: 35rem;
                }
            }
    
            &:nth-child(3) {
        
                @media (max-width: $screen-m) {
                    width: 20%;
                }
        
                @media (max-width: $screen-s) {
                    width: 22%;
                }
            }
    
            &:nth-child(4) {
                width: 13%;
    
                @media (max-width: $screen-s) {
                    padding-right: 0;
                }
            }
        }
    }

    .winners ~ .participants__body {

        .participants-item {

            &:nth-child(1),
            &:nth-child(2),
            &:nth-child(3) {
                display: none;

                @media (max-width: $screen-s) {
                    display: block;
                }
            }
        }
    }
}
