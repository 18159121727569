.about-s5 {
    height: calc(100svh - 76rem);
    position: relative;

    .about-screen__inner {
        justify-content: flex-end;
    }

    @media (max-width: $screen-m) {
        height: auto;
    }
}

.about-s5__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: $screen-m) {
        margin-top: 80rem;
        margin-bottom: 20rem;
    }

    @media (max-width: $screen-s) {
        display: block;
        margin-top: 60rem;
        margin-bottom: -10rem;
    }
}

.about-s5__text {
    width: 50%;
    color: var(--color-dark);
    margin-top: auto;
    line-height: 1.1;

    @media (max-width: $screen-m) {
        width: 91%;
    }

    @media (max-width: $screen-s) {
        width: 100%;
    }
}

.about-s5__img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 42%;
    height: 100%;
    z-index: 1;
    overflow: hidden;

    @media (max-width: $screen-m) {
        width: 100%;
        position: relative;
        aspect-ratio: 1;
    }
}

.about-s5__img-inner {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;

    img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
    }
}
