.blockquote {
    display: block;
    margin: 20rem 0;
    padding: 88rem 24rem 56rem 24rem;
    background: #ffece5;
    border-radius: 12rem;
    font-weight: 300;
    font-variation-settings: 'wght' 300;
    position: relative;
    line-height: 1.4;

    @media (max-width: $screen-s) {
        padding: 88rem 20rem 46rem 20rem;
    }

    &:before {
        content: '';
        display: block;
        width: 18rem;
        height: 15rem;
        background: url('../img/quote.svg') no-repeat 0 0;
        background-size: 100% 100%;
        position: absolute;
        left: 26rem;
        top: 48rem;

        @media (max-width: $screen-s) {
            left: 22rem;
        }
    }
}

.blockquote__content {
    p {

        &:first-child {
            margin-top: 0;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}

.blockquote__footer {
    margin-top: 20rem;
    font-variation-settings: 'wght' 500;
}
