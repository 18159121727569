.user {
    display: flex;
    align-items: center;
    gap: 10rem;
    position: relative;
}

.user__notifications {
    width: 24rem;
    height: 24rem;
    color: var(--color-orange);
    position: relative;

    svg {
        display: block;
        width: 100%;
        height: 100%;
        position: relative;
        z-index: 1;
    }
    
    .user__notifications-counter {
        position: absolute;
        background: var(--color-orange);
        color: #fff;
        min-width: 13rem;
        height: 13rem;
        padding: 0 3rem;
        border-radius: 7rem;
        position: absolute;
        right: 1rem;
        top: -1rem;
        z-index: 2;
        border: 2rem solid #fff;
        box-sizing: border-box;
        text-align: center;
        font-size: 7rem;
        line-height: 10rem;
        font-variation-settings: 'wght' 600;
    }
}

.user__trigger {
    width: 68rem;
    height: 40rem;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    background: #F1F3F6;
    border-radius: 12rem;
    padding: 0 10rem;
    color: var(--color-dark);
    justify-content: space-between;
    transition: color 0.2s ease;

    @media (max-width: $screen-s) {
        width: 32rem;
        height: 32rem;
        padding: 0;
        border-radius: 16rem;
    }

    html.desktop &:hover {
        color: var(--color-orange);
    }

    svg {
        width: 24rem;
        height: 24rem;
        flex-shrink: 0;
        transition: transform 0.2s ease;

        @media (max-width: $screen-s) {
            display: none;
        }

        .user._is-active & {
            transform: scaleY(-1);
        }
    }
}

.user__trigger-img {
    width: 20rem;
    height: 20rem;
    border-radius: 10rem;
    background: var(--color-grey-light) url('../img/dummy_male.svg') no-repeat center bottom;
    background-size: 80% auto;
    overflow: hidden;
    flex-shrink: 0;

    @media (max-width: $screen-s) {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }

    &._female {
        background-image: url('../img/dummy_female.svg')
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.user__menu {
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 14rem;
    background: #fff;
    min-width: 230rem;
    box-shadow: 0rem 10rem 30rem 0rem rgba(0, 0, 0, 0.10);
    border-radius: 12rem;
    padding: 20rem 0;
    box-sizing: border-box;
    font-size: 14rem;
    font-variation-settings: 'wght' 500;
    opacity: 0;
    transform: translateY(-10rem);
    pointer-events: none;
    transition: opacity 0.3s ease,
                transform 0.3s ease,
                visibility 0s linear 0.3s;

    .user._is-active & {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        pointer-events: auto;
        transition: opacity 0.3s ease,
                    transform 0.3s ease;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        a {
            display: flex;
            color: var(--color-dark);
            padding: 7rem 20rem;
            align-items: center;

            html.desktop &:hover {
                color: var(--color-orange);
            }

            &.user__logout {
                color: var(--color-grey);
            }
        }
    }
}

.user__logout {
    gap: 4rem;
    margin-top: 10rem;

    svg {
        width: 24rem;
        height: 24rem;
        margin-left: -4rem;
    }
}
