.about-screen {
    position: relative;
    z-index: 1;
}

.about-s1 {
    height: calc(100svh - 76rem);
    position: relative;

    @media (max-width: $screen-m) {
        height: auto;
        min-height: calc(100svh - 76rem);
    }

    .about-screen__inner {

        @media (max-width: $screen-m) {
            min-height: calc(100svh - 76rem);
        }
        
        @media (max-width: $screen-s) {
            padding-bottom: 20rem;
        }
    }
}

.about-screen__inner {
    @include wrap();
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 0 40rem 0;
    box-sizing: border-box;
    position: relative;
    z-index: 2;

    @media (min-width: $screen-l) {
        // padding-bottom: 60rem;
    }

    @media (max-width: $screen-m) {
        padding-bottom: 50rem;
    }

    @media (max-width: $screen-s) {
        padding-bottom: 60rem;
    }
}

.about-s1__title {
    font-size: 110rem;
    font-variation-settings: 'wght' 800;
    line-height: 1.2;
    text-transform: uppercase;
    color: #fff;
    margin: 10rem 0 0 0 !important;
    overflow: hidden;
    position: relative;

    @media (min-width: $screen-l) {
        font-size: 152rem;
    }

    @media (max-width: $screen-m) {
        font-size: 82rem;
    }

    @media (max-width: $screen-s) {
        font-size: 52rem;
        margin-top: 0 !important;
    }

    > span {
        display: block;
        position: relative;
        transform: translateY(50%);
        opacity: 0;

        html._is-preloaded & {
            transform: translateY(0);
            opacity: 1;
            transition: all 0.7s ease-out 0.1s;
        }
    }
}

.about-s1__link {
    display: block;
    width: 350rem;
    border-radius: 20rem;
    background: #fff;
    position: relative;
    padding: 20rem 20rem 90rem 20rem;
    box-sizing: border-box;
    transition: color 0.2s ease;

    @media (max-width: $screen-m) {
        width: 320rem;
    }

    @media (max-width: $screen-s) {
        width: 100%;
    }

    html.desktop &:hover {

        svg {
            right: 15rem;
        }
    }

    span {
        display: block;
        font-size: 24rem;
        font-variation-settings: 'wght' 700;
        text-transform: uppercase;

        @media (max-width: $screen-m) {
            font-size: 20rem;
        }
    }

    svg {
        width: 50rem;
        height: 50rem;
        position: absolute;
        bottom: 20rem;
        right: 20rem;
        transition: right 0.2s ease;

        @media (max-width: $screen-m) {
            width: 40rem;
            height: 40rem;
            right: 15rem;
            bottom: 15rem;
        }
    }
}

.about-s1__bg {
    position: absolute;
    left: 0;
    top: -76rem;
    width: 100%;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(109deg, #CCC1B7 0%, #7B9FB2 0.01%, #E0D4C4 93.08%);

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        z-index: 2;
        background: #000;
        opacity: 0.4;
        pointer-events: none;
    }
}

.about-s1__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    transform: translateY(10%);
    opacity: 0;

    html._is-preloaded & {
        transform: translateY(0);
        opacity: 1;
        transition: all 0.7s ease-out 0.3s;
    }

    @media (max-width: $screen-m) {
        margin-top: 100rem;
    }

    @media (max-width: $screen-s) {
        display: block;
    }
}

.about-screen__text {
    color: #fff;
    line-height: 1.4;
    margin-bottom: 10rem;

    p:first-child {
        margin-top: 0;
    }

    p:last-child {
        margin-bottom: 0;
    }

    em {
        font-style: normal;
        color: var(--color-orange);
    }
}

.about-s1__text {
    width: 40%;

    @media (max-width: $screen-m) {
        width: 48%;
    }

    @media (max-width: $screen-s) {
        width: 100%;
        margin-bottom: 34rem;
    }
}

.about-screen__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    overflow: hidden;

    img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
    }
}

.about-screen__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    overflow: hidden;

    video {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
    }
}
