.picture {
    width: 100%;
}

.picture__img {
    background: grey;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 12rem;
}

.picture__img-img {
    display: block;
    max-height: 630rem;
    max-width: 100%;
    position: relative;
    z-index: 2;

    @media (min-width: $screen-l) {
        max-height: 900rem;
    }

    @media (max-width: $screen-m) {
        max-height: 530rem;
    }

    @media (max-width: $screen-s) {
        max-height: 150vw;
    }
}

.picture__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background-color: var(--color-platina);
    pointer-events: none;

    img {
        width: 110%;
        height: 110%;
        object-fit: cover;
        opacity: 0.7;
        filter: blur(20rem);
    }
}

.picture__caption {
    margin-top: 16rem;
    color: var(--color-grey);
    font-variation-settings: 'wght' 300;

    @media (max-width: $screen-s) {
        margin-top: 12rem;
    }
}
