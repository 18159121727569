.calendar-item {
    position: relative;
    margin-bottom: -2rem;

    &:after {
        content: '';
        display: block;
        position: absolute;
        top: 0;
        left: 20rem;
        right: 20rem;
        border-bottom: max(1px, 1rem) solid rgba(0,0,0,0.1);

        @media (max-width: $screen-s) {
            left: 0;
            right: 0;
        }
    }

    &:first-child {

        &:after {
            content: none;
        }
    }

    &._is-finished {
        color: var(--color-grey);

        .tag {
            background: #F1F3F6;
            color: var(--color-grey);
        }
    }

    &._is-vip {

        &:after {
            content: none;  
        }

        + .calendar-item {

            &:after {
                content: none;  

                @media (max-width: $screen-m) {
                    content: '';
                }
            }   
        }
    }

    .tag {
        @media (max-width: $screen-s) {
            font-size: 12rem;
            padding: 0 8rem;
            height: 24rem;
            line-height: 25rem;
        }
    }
}

.calendar-item__inner {
    display: flex;
    position: relative;
    z-index: 2;
    pointer-events: none;
    padding: 35rem 0 35rem 0;
    transition: color 0.2s ease;

    .calendar-item._is-vip & {
        background: rgba(255, 66, 0, 0.07);
        border-radius: 12rem;
    }

    @media (max-width: $screen-m) {
        padding: 20rem 0;
        flex-wrap: wrap;
        gap: 10rem 0;
    }

    @media (max-width: $screen-s) {
        padding: 20rem 0 24rem 0;
    }

    a {
        pointer-events: auto;
    }
}

.calendar-item__cell {
    flex-shrink: 0;
    box-sizing: border-box;
    padding-right: 12rem;

    @media (max-width: $screen-s) {
        padding-right: 10rem;
    }

    &:nth-child(1) {
        width: 22%;
        padding-left: 20rem;

        @media (max-width: $screen-m) {
            width: 32%;
        }

        @media (max-width: $screen-s) {
            padding-left: 10rem;
            width: 100%;
            order: 2;
        }

        .calendar-item__date,
        .calendar-item__country {

            @media (min-width: ($screen-m + 1)) {
                display: none;
            }

            @media (max-width: $screen-s) {
                display: none;
            }
        }

        .tag {
            @media (min-width: ($screen-s + 1)) {
                display: none;
            }
        }

        .calendar-item__country {
            @media (max-width: $screen-m) {
                display: inline-flex;
            }

            @media (max-width: $screen-s) {
                display: inline-flex;
                margin-top: 6rem !important;
            }
        }

        .tag {
            margin-top: 10rem;
            vertical-align: middle;
        }

        > br {
            display: none;

            @media (max-width: $screen-s) {
                display: block;
            }
        }
    }

    &:nth-child(2) {
        width: 16%;

        @media (max-width: $screen-m) {
            display: none;
        }

        @media (max-width: $screen-s) {
            order: 1;
            display: block;
            width: 100%;
        }
    }

    &:nth-child(3) {
        width: 18%;

        @media (max-width: $screen-m) {
            display: none;
        }

        @media (max-width: $screen-s) {
            // display: block;
            width: auto;
        }
    }

    &:nth-child(4) {
        width: 12%;

        @media (max-width: $screen-m) {
            width: 19%;
        }

        @media (max-width: $screen-s) {
            width: auto;
            display: none;
        }
    }

    &:nth-child(5) {
        width: 16%;

        @media (max-width: $screen-m) {
            width: 21%;
        }

        @media (max-width: $screen-s) {
            width: auto;
            display: none;
        }
    }

    &:nth-child(6) {
        width: 16%;

        @media (max-width: $screen-m) {
            width: 28%;
        }

        @media (max-width: $screen-s) {
            width: 100%;
            text-align: center;
            padding-right: 10rem;
            padding-left: 10rem;
            order: 6;
            padding-top: 10rem;
            
            .btn {
                width: 100%;
            }
        }
    }
}

.calendar-item__name {
    font-variation-settings: 'wght' 600;
    padding-top: 5rem;

    @media (min-width: $screen-l) {
        font-size: 16rem;
        padding-top: 6rem;
    }

    @media (max-width: $screen-m) {
        padding-top: 7rem;
    }

    @media (max-width: $screen-s) {
        padding-top: 0;
    }
}

.calendar-item__date {
    font-variation-settings: 'wght' 600;
    padding-top: 5rem;

    @media (min-width: $screen-l) {
        font-size: 16rem;
        padding-top: 6rem;
    }

    @media (max-width: $screen-m) {
        font-size: 12rem;
        padding-top: 0;
        margin-top: 10rem;
    }

    @media (max-width: $screen-s) {
        color: var(--color-orange);
        margin-top: 0;
        padding-left: 10rem;
    }
}

.calendar-item__status {
    font-size: 10rem;
    font-variation-settings: 'wght' 500;
    margin-top: 10rem;

    &:first-child {
        margin-top: 0;
        padding-top: 5rem;

        @media (max-width: $screen-m) {
            padding-top: 7rem;
        }

        @media (max-width: $screen-s) {
            padding-top: 0;
        }
    }

    b {
        font-size: 16rem;
        font-weight: normal;
        font-variation-settings: 'wght' 600;

        @media (max-width: $screen-m) {
            font-size: 12rem;
        }
    }
}

.calendar-item__link {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    box-sizing: border-box;
    border-radius: 12rem;
    border: 2rem solid transparent;
    z-index: 1;
    overflow: hidden;
    transition: border-color 0.2s ease;

    html.desktop &:hover {
        border-color: var(--color-orange);

        ~ .calendar-item__inner {
            color: var(--color-orange);
        }
    }

    span {
        @include v-hidden();
    }
}
