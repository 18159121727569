.rating-item__inner {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    padding: 10rem 0;
    transition: color 0.2s ease;
    background: #fff;
    border-radius: 12rem;
    border: 2rem solid transparent;
    color: var(--color-dark);
    transition: all 0.2s ease;

    html.desktop &:hover {
        border-color: var(--color-orange);
    }

    @media (max-width: $screen-m) {
        padding: 10rem 0;
        flex-wrap: wrap;
        gap: 10rem 0;
    }

    @media (max-width: $screen-s) {
        padding: 12rem 0;
        align-items: flex-start;
    }

    b {
        font-weight: normal;
        font-variation-settings: 'wght' 600;
    }
}

.rating-item__cell {
    flex-shrink: 0;
    box-sizing: border-box;
    padding-right: 12rem;

    @media (max-width: $screen-s) {
        font-size: 11rem;
    }

    &:nth-child(1) {
        width: 12%;
        padding-left: 30rem;
        font-variation-settings: 'wght' 500;
        white-space: nowrap;

        @media (max-width: $screen-m) {
            padding-left: 20rem;
            width: 15%;
        }

        @media (max-width: $screen-s) {
            padding-left: 10rem;
            padding-right: 0;
            width: 15%;
            font-size: 10rem;
        }

        i {
            color: rgba(0,0,0,0.4);
            margin-left: 2rem;
            font-style: normal;

            @media (max-width: $screen-s) {
                display: block;
                margin-left: 0 !important;
            }

            &._up,
            &._down {
                color: var(--color-green);
                padding-left: 12rem;
                position: relative;
                margin-left: 4rem;

                @media (max-width: $screen-s) {
                    padding-left: 8rem;
                }

                &:before {
                    content: '';
                    width: 9rem;
                    height: 5rem;
                    display: block;
                    position: absolute;
                    left: 0;
                    top: 50%;
                    margin-top: -3rem;
                    background: url('../img/rating_up.svg') no-repeat 0 0;
                    background-size: 100% auto;

                    @media (max-width: $screen-s) {
                        width: 6rem;
                        height: 3rem;
                        margin-top: -1rem;
                    }
                }
            }

            &._down {
                color: var(--color-red);

                &:before {
                    background-image: url('../img/rating_down.svg');
                    margin-top: -2rem;

                    @media (max-width: $screen-s) {
                        margin-top: -1rem;
                    }
                }
            }
        }
    }

    &:nth-child(2) {
        width: 26%;

        @media (max-width: $screen-m) {
            width: 36%;
        }

        @media (max-width: $screen-s) {
            width: 51%;
        }

        .tag {
            display: none;

            @media (max-width: $screen-s) {
                display: inline-flex;
                margin-top: 6rem;
                margin-left: 34rem;
            }
        }
    }

    &:nth-child(3) {
        width: 18%;

        @media (max-width: $screen-m) {
            width: 20%;
            width: 19%;
        }

        @media (max-width: $screen-s) {
            display: none;
        }
    }

    &:nth-child(4) {
        width: 20%;

        @media (max-width: $screen-m) {
            display: none;
        }
    }

    &:nth-child(5) {
        width: 10%;
        font-size: 14rem;

        @media (max-width: $screen-m) {
            width: 15%;
            font-size: 12rem;
        }

        @media (max-width: $screen-s) {
            width: 18%;
            font-size: 10rem;
        }
    }

    &:nth-child(6) {
        width: 14%;
        font-size: 14rem;

        @media (max-width: $screen-m) {
            width: 15%;
            font-size: 12rem;
        }

        @media (max-width: $screen-s) {
            width: 16%;
            font-size: 10rem;
        }
    }

    .tag {
        @media (max-width: $screen-s) {
            font-size: 10rem;
            padding: 0 8rem;
            height: 20rem;
            line-height: 21rem;
        }
    }
}

.rating-item__person {
    display: flex;
    align-items: center;

    @media (max-width: $screen-s) {
        align-items: flex-start;
    }
}

.rating-item__person-img {
    border-radius: 50%;
    width: 70rem;
    height: 70rem;
    overflow: hidden;
    margin-right: 20rem;
    background: var(--color-grey-light) url('../img/dummy_male.svg') no-repeat center center;
    background-size: 33% auto;
    flex-shrink: 0;

    @media (max-width: $screen-m) {
        width: 44rem;
        height: 44rem;
        margin-right: 12rem;
    }

    @media (max-width: $screen-s) {
        width: 24rem;
        height: 24rem;
        margin-right: 10rem;
    }

    &._female {
        background-image: url('../img/dummy_female.svg')
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.rating-item__person-name {
    font-variation-settings: 'wght' 600;

    span {
        display: block;
    }
}
