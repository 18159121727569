.calendar {
    text-align: center;
}

.calendar__filter {
    margin: 30rem 0;
}

.filter__inner {
    display: flex;
    flex-wrap: wrap;
    gap: 10rem;
    text-align: left;
    margin-bottom: 40rem;

    .select {

        @media (max-width: $screen-s) {
            width: 100%;
        }
    }
}

.calendar__head {
    display: flex;
    margin-bottom: 10rem;
    font-variation-settings: 'wght' 300;
    text-align: left;

    @media (min-width: $screen-l) {
        font-size: 16rem;
    }

    @media (max-width: $screen-s) {
        font-size: 10rem;
        margin-bottom: 0;
        display: none;
    }

    > div {
        flex-shrink: 0;
        box-sizing: border-box;
        padding-right: 12rem;

        @media (max-width: $screen-s) {
            padding-right: 10rem;
        }

        &:nth-child(1) {
            width: 22%;

            @media (max-width: $screen-m) {
                width: 32%;
            }

            @media (max-width: $screen-s) {
                width: 50%;
            }
        }

        &:nth-child(2) {
            width: 16%;

            @media (max-width: $screen-m) {
                display: none;
            }
        }

        &:nth-child(3) {
            width: 18%;

            @media (max-width: $screen-m) {
                display: none;
            }
        }

        &:nth-child(4) {
            width: 12%;

            @media (max-width: $screen-m) {
                width: 19%;
            }

            @media (max-width: $screen-s) {
                width: 25%;
            }
        }

        &:nth-child(5) {
            width: 16%;

            @media (max-width: $screen-m) {
                width: 21%;
            }

            @media (max-width: $screen-s) {
                width: 25%;
            }
        }

        &:nth-child(6) {
            width: 16%;

            @media (max-width: $screen-m) {
                width: 28%;
            }

            @media (max-width: $screen-s) {
                display: none;
            }
        }
    }
}

.calendar__body {
    display: grid;
    grid-template-columns: 1fr;
    // gap: 10rem;
    text-align: left;

    @media (max-width: $screen-m) {
        gap: 10rem;
    }
}

.calendar__load-more {
    display: inline-flex;
    align-items: center;
    gap: 10rem;
    height: 48rem;
    font-size: 14rem;
    font-variation-settings: 'wght' 500;
    border: 0;
    background: none;
    transition: color 0.2s ease;
    color: var(--color-dark);

    html.desktop &:hover {
        color: var(--color-orange);
    }

    svg {
        width: 24rem;
        height: 24rem;
    }
}
