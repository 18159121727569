.winners {
    display: flex;
    gap: 16rem;
    align-items: flex-end;
    margin: 80rem 0 100rem 0;

    @media (max-width: $screen-s) {
        display: none;
    }
}

.winner {
    width: 33%;
    background: #fff;
    border-radius: 20rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding-bottom: 20rem;

    &:nth-child(1) {
        order: 2;
        min-height: 290rem;

        @media (max-width: $screen-m) {
            min-height: 200rem;
        }
    }

    &:nth-child(2) {
        order: 1;
        min-height: 210rem;

        @media (max-width: $screen-m) {
            min-height: 170rem;
        }
    }

    &:nth-child(3) {
        order: 3;
        min-height: 180rem;

        @media (max-width: $screen-m) {
            min-height: 130rem;
        }
    }
}

.winner__place {
    color: var(--color-orange);
    display: block;
    font-size: 64rem;
    line-height: 1;
    font-variation-settings: 'wght' 700;
    margin-top: 14rem;
    margin-bottom: 12rem;

    @media (max-width: $screen-m) {
        font-size: 36rem;
        margin-top: 10rem;
    }

    .winner:nth-child(1) & {
        font-size: 110rem;
        margin-top: 30rem;
        margin-bottom: 20rem;

        @media (max-width: $screen-m) {
            font-size: 64rem;
        }
    }
}

.winner__names {
    width: 100%;
    margin-top: auto;
}

.winner__names-item {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
    font-variation-settings: 'wght' 600;
    margin-top: 4rem;

    @media (max-width: $screen-m) {
        font-size: 12rem;
    }
}

.winner__images {
    display: flex;
    justify-content: center;
    margin-top: -36rem;

    @media (max-width: $screen-m) {
        margin-top: -20rem;
    }
}

.winner__image {
    border-radius: 50%;
    width: 65rem;
    height: 65rem;
    overflow: hidden;
    background: var(--color-grey-light) url('../img/dummy_male.svg') no-repeat center center;
    background-size: 33% auto;
    flex-shrink: 0;
    border: 3rem solid #fff;
    position: relative;
    z-index: 1;
    margin: 0 -3rem;

    @media (max-width: $screen-m) {
        width: 35rem;
        height: 35rem;
    }

    @media (max-width: $screen-s) {
        width: 24rem;
        height: 24rem;
        margin-right: 10rem;
    }

    &:nth-child(1) {
        z-index: 2;
    }

    &._female {
        background-image: url('../img/dummy_female.svg')
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
