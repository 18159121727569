.gallery {
    background: var(--color-platina);
    border-radius: 20rem;
    padding-top: 1px;
    padding-bottom: 30rem;
}

.gallery__inner {
    @include wrap();
    text-align: center;
}

.gallery__title {
    text-align: left;
}

.gallery__grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16rem;
    text-align: left;
    margin-bottom: 30rem;

    @media (max-width: $screen-m) {
        grid-template-columns: 1fr 1fr;
        gap: 12rem;
    }

    @media (max-width: $screen-s) {
        grid-template-columns: 1fr;
    }
}

.gallery-item {
    display: block;
    aspect-ratio: 1;
    overflow: hidden;
    position: relative;
    border-radius: 12rem;
    background: #fff;

    html.desktop &:hover {

        img {
            transform: scale(1.06);
        }
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        transition: transform 1s;
        z-index: 1;
        will-change: transform;
    }
}

.gallery-item--video {

    &:before {
        content: '';
        display: block;
        width: 100%;
        height: 40%;
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.80) 0%, rgba(0, 0, 0, 0.00) 100%);
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
    }

    &:after {
        content: '';
        display: block;
        width: 24rem;
        height: 24rem;
        background: url('../img/play.svg') no-repeat 0 0;
        background-size: 100% auto;
        position: absolute;
        bottom: 20rem;
        left: 20rem;
        z-index: 3;
    }
}

.gallery__load-more {
    display: inline-flex;
    align-items: center;
    gap: 10rem;
    height: 48rem;
    font-size: 14rem;
    font-variation-settings: 'wght' 500;
    border: 0;
    background: none;
    transition: color 0.2s ease;
    color: var(--color-dark);

    html.desktop &:hover {
        color: var(--color-orange);
    }

    svg {
        width: 24rem;
        height: 24rem;
    }
}

.lg-backdrop.in {
    opacity: 0.75;
}

.lg-object {
    border-radius: 12rem;
}
