.about-s6 {
    height: calc(100svh - 76rem);
    position: relative;
    background: #141D2E !important;

    @media (max-width: $screen-m) {
        height: auto;
    }

    .about-screen__inner {

        @media (max-width: $screen-m) {
            padding-bottom: 80rem;
            display: block;
            padding-top: 1px;
        }

        @media (max-width: $screen-s) {
            padding-bottom: 60rem;
        }
    }
}

.about-s6__top {
    color: #fff;
    margin-top: 60rem;

    @media (min-width: $screen-l) {
        margin-top: 100rem;
    }

    @media (max-width: $screen-m) {
        margin-top: 80rem;
    }

    @media (max-width: $screen-s) {
        margin-top: 60rem;
    }
}

.about-s6__top-text {
    width: 33%;
    font-size: 20rem;
    font-variation-settings: 'wght' 600;

    @media (min-width: $screen-l) {
        font-size: 24rem;
        width: 42%;
    }

    @media (max-width: $screen-m) {
        width: 75%;
        font-size: 18rem;
    }

    @media (max-width: $screen-s) {
        width: 100%;
        font-size: 16rem;
    }
}

.about-s6__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: $screen-m) {
        margin-top: 40rem;
    }

    @media (max-width: $screen-s) {
        display: block;
        margin-top: 40rem;
    }
}

.about-s6__text {
    width: 33%;
    margin-top: auto;

    @media (max-width: $screen-m) {
        width: 75%;
    }

    @media (max-width: $screen-s) {
        width: 100%;
    }
}

.about-s6__img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 42%;
    height: 100%;
    z-index: 1;
    overflow: hidden;

    @media (max-width: $screen-m) {
        width: 100%;
        position: relative;
        aspect-ratio: 1;
    }

    img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
    }
}

.about-s6__circles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16rem;
    position: absolute;
    right: 0;
    top: 50%;
    --circle-width: 280rem;
    --gap: 16rem;
    margin-top: calc(var(--circle-width) * -1 - var(--gap) / 2);
    width: calc(var(--circle-width) * 3 + var(--gap) * 2);

    @media (min-width: $screen-l) {
        --circle-width: 396rem;
    }

    @media (max-width: $screen-m) {
        position: relative;
        top: unset;
        margin-top: 30rem;
        --circle-width: 100%;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 12rem;
        width: 100%;
    }
    
    @media (max-width: $screen-s) {
        grid-template-columns: 1fr 1fr;
    }
}

.about-s6__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--circle-width);
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
    background: linear-gradient(109deg, rgba(204, 193, 183, 0.30) 0%, rgba(123, 159, 178, 0.30) 0.01%, rgba(224, 212, 196, 0.30) 93.08%);
    color: #fff;
    flex-shrink: 0;

    @media (max-width: $screen-m) {
        grid-column-start: unset !important;
        grid-row-start: unset !important;
    }

    &:nth-child(1) {
        grid-column-start: 1;
        grid-row-start: 1;
    }

    &:nth-child(2) {
        grid-column-start: 2;
        grid-row-start: 1;
    }

    &:nth-child(3) {
        grid-column-start: 2;
        grid-row-start: 2;
    }

    &:nth-child(4) {
        grid-column-start: 3;
        grid-row-start: 2;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.about-s6__circle-text {
    width: 80%;
    text-align: center;
    font-size: 14rem;
    line-height: 1.5;

    @media (min-width: $screen-l) {
        font-size: 20rem;
    }

    @media (max-width: $screen-m) {
        font-size: 11rem;
    }
}

.about-s6__img {
    position: absolute;
    // left: -42%;
    left: -50%;
    bottom: 0;
    width: 50%;
    height: 100%;
    z-index: 1;
    overflow: hidden;
    display: none;
}

.about-s6__img-inner {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;

    img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
    }
}
