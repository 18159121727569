.page-about {

    body {
        @include net();
    }

    .header {
        background: transparent;
        border-bottom-color: transparent;
    }

    .header__logo-ball {
        color: #fff;
    }

    &._is-scrolled {

        .header {
            background: #fff;
            border-bottom-color: rgba(0, 0, 0, 0.1);
        }
    }
}


.about-scroll._is-scrollable {

    .about-scroll__inner {
        display: flex;
        position: relative;
        transform: translateX(calc(var(--progress) * -500%));

        > div {
            flex-shrink: 0;
            width: 100%;
        }
    }

    .about-screen {
        @include net();
    }

    .about-s2 {
        --p: max(calc(var(--local-progress) - 0.5), 0);
        transform: translateX( calc(100% * var(--p)) );

        &:after {
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            background: #000;
            opacity: calc((var(--local-progress) - 0.5) * 2);
            z-index: 3;
            pointer-events: none;
        }
    }

    .about-s2__title {
        transform: translateX(calc(20% - 40% * var(--local-progress)));
    }


    .about-s3__ball-1 {
        transform: translateX(calc(600% - 1200% * var(--local-progress)));

        > div {
            transform: rotate(calc(var(--local-progress) * -1.5 * 360deg));
        }
    }

    .about-s3__ball-2 {
        transform: translateX(calc(1000% - 2000% * var(--local-progress)));

        > div {
            transform: rotate(calc(var(--local-progress) * -2 * 360deg));
        }
    }


    .about-s4__bg-inner {
        width: 120% !important;
        transform: translateX(calc(20% * var(--local-progress)));
    }

    .about-s5__img {
        display: none;
    }

    .about-s6__img {
        display: block !important;
        will-change: transform;
        transform: translateX(
            calc(30% * (0.5 - var(--local-progress)))
        );
    }
    
    .about-s6__img-inner {
        width: 130% !important;
        transform: translateX(calc(30% * var(--local-progress)));
    }


    @media (min-aspect-ratio: 2) {

        .about-s2__title {
            margin-top: 60rem;

            @media (min-width: $screen-l) {
                margin-top: 80rem;
            }
        }

        .about-s3__ball-1 {
            top: 20%;
        }

        .about-s3__ball-2 {
            top: 5%;
        }

        .about-s3__circles,
        .about-s6__circles {
            --circle-width: 240rem;

            @media (min-width: $screen-l) {
                --circle-width: 280rem;
            }
        }

        .about-s3__circle-text,
        .about-s6__circle-text {
            font-size: 12rem;

            @media (min-width: $screen-l) {
                font-size: 14rem;
            }
        }
    }
}

.about-scroll:not(._is-scrollable) {

    @media (min-aspect-ratio: 2) and (min-width: ($screen-m + 1)) {
        
        .about-screen,
        .about-screen__inner {
            height: auto !important;
            min-height: calc(100svh - 76rem);
        }


        .about-s2__bottom {
            margin-top: 80rem;
        }


        .about-s3 {

            .about-screen__inner {
                min-height: 700rem;

                @media (min-width: $screen-l) {
                    min-height: 840rem;
                }
            }
        }

        .about-s3__circles,
        .about-s6__circles {
            --circle-width: 240rem;

            @media (min-width: $screen-l) {
                --circle-width: 280rem;
            }
        }

        .about-s3__circle-text,
        .about-s6__circle-text {
            font-size: 12rem;

            @media (min-width: $screen-l) {
                font-size: 14rem;
            }
        }


        .about-s4 {
            aspect-ratio: 2;
        }


        .about-s5__bottom {
            margin-top: 100rem;

            @media (min-width: $screen-l) {
                margin-top: 200rem;
            }
        }


        .about-s6 {

            .about-screen__inner {
                min-height: 700rem;

                @media (min-width: $screen-l) {
                    min-height: 840rem;
                }
            }
        }
    }
}
