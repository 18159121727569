.top {
    aspect-ratio: 3.215;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    color: #fff;
    padding: 40rem;
    box-sizing: border-box;
    margin-bottom: 90rem;

    @media (min-width: $screen-l) {
        margin-bottom: 100rem;
        padding: 50rem;
    }

    @media (max-width: $screen-m) {
        aspect-ratio: 1.77;
        padding: 30rem 30rem 40rem 30rem;
        margin-bottom: 80rem;
    }

    @media (max-width: $screen-s) {
        padding: 30rem 6vw;
        width: 100vw;
        margin-left: -6vw;
        aspect-ratio: 0.9;
        margin-bottom: 60rem;
    }

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        z-index: 2;
        background: #000;
        opacity: 0.4;
        pointer-events: none;
        border-radius: 12rem;

        @media (max-width: $screen-s) {
            border-radius: 0;
        }
    }
}

.top__img {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    border-radius: 12rem;
    overflow: hidden;

    @media (max-width: $screen-s) {
        border-radius: 0;
    }

    img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
    }
}

.top__top {
    position: relative;
    z-index: 3;
    pointer-events: none;
}

.top__pubdate {
    font-size: 14rem;
    pointer-events: auto;

    @media (min-width: $screen-l) {
        font-size: 18rem;
    }
}

.top__bottom {
    position: relative;
    z-index: 3;
    margin-top: 80rem;
    pointer-events: none;

    @media (min-width: $screen-l) {
        margin-top: 100rem;
    }

    @media (max-width: $screen-s) {
        margin-top: 60rem;
    }
}

.top__title {
    max-width: 1000rem;
    font-size: 48rem;
    line-height: 1.2;
    font-weight: 400;
    font-variation-settings: 'wght' 500;
    margin: 0;
    pointer-events: auto;

    @media (min-width: $screen-l) {
        font-size: 64rem;
        max-width: 1316rem;
    }

    @media (max-width: $screen-m) {
        font-size: 32rem;
    }

    @media (max-width: $screen-s) {
        font-size: 28rem;
    }
}

.top__tags {
    margin-top: 18rem;
    display: flex;
    gap: 10rem;
    flex-wrap: wrap;

    @media (max-width: $screen-s) {
        margin-top: 10rem;
    }
}

.top__bottom-inner {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    gap: 0 80rem;

    @media (max-width: $screen-m) {
        gap: 0 60rem;
    }

    @media (max-width: $screen-s) {
        flex-wrap: wrap;
    }

    a,
    button {
        pointer-events: auto;
    }
}

.top__bottom-join {
    text-align: right;

    @media (max-width: $screen-s) {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-top: 16rem;
    }
}

.top__price {
    font-size: 48rem;
    line-height: 1.2;
    font-weight: 400;
    font-variation-settings: 'wght' 500;
    margin-bottom: 10rem;
    pointer-events: auto;

    @media (min-width: $screen-l) {
        font-size: 64rem;
        max-width: 1316rem;
    }

    @media (max-width: $screen-m) {
        font-size: 32rem;
    }

    @media (max-width: $screen-s) {
        font-size: 28rem;
        margin-bottom: 0;
    }
}
