.constructor-block {
    margin-bottom: 20rem;

    &:first-child {
        > *:first-child {
            margin-top: 0;

            > *:first-child {
                margin-top: 0;
            }
        }
    }
}

.constructor-block--margin-large {
    margin-bottom: 100rem;

    @media (max-width: $screen-m) {
        margin-bottom: 80rem;
    }

    @media (max-width: $screen-s) {
        margin-bottom: 60rem;
    }
}
