.gallery-popup {
    position: fixed;
    width: 100%;
    height: 100svh;
    z-index: 10;
    left: 0;
    top: 0;
    visibility: hidden;
    opacity: 0;
    --duration: 0.5s;
    transition: opacity var(--duration) ease,
                transform var(--duration) ease,
                visibility 0s linear var(--duration);

    &._is-open {
        visibility: visible;
        opacity: 1;
        transition: opacity var(--duration) ease,
                    transform var(--duration) ease;
    }

    .swiper {
        width: 100%;
        height: 100%;
        --swiper-preloader-color: var(--color-orange);
    }

    .swiper-slide {
        padding: max(10svh, 100rem) 3vw;
        box-sizing: border-box;

        @media (max-width: $screen-m) {
            padding: max(10svh, 80rem) 4vw;
        }

        @media (max-width: $screen-s) {
            padding: max(10svh, 64rem) 0;
        }

        img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: contain;
            position: relative;
            z-index: 2;
        }
    }

    .swiper-slide__inner {
        position: relative;
        height: 100%;
    }

    .swiper-lazy-preloader {
        pointer-events: none;
    }
}

.gallery-popup__inner {
    position: absolute;
    height: 100%;
    left: 0;
    right: 0;
    z-index: 2;
    overflow: hidden;
}

.gallery-popup__bg {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(0,0,0,0.8);
    backdrop-filter: blur(5px);
}

.gallery-popup__video {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}

.gallery-popup__video-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    background: #000;

    iframe {
        width: 100% !important;
        height: 100% !important;
    }
}

.gallery-popup__video-preview {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    cursor: pointer;

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover !important;
        position: relative;
        z-index: 1;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: #000;
        opacity: 0.5;
        z-index: 2;
    }

    .gallery-popup__video._is-playing & {
        display: none;
    }
}

.gallery-popup__video-play {
    display: block;
    width: 38rem;
    height: 38rem;
    background: url('../img/play.svg') no-repeat center center;
    background-size: 100% 100%;
    padding: 0;
    border: 0;
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    margin: -19rem 0 0 -19rem;
    overflow: hidden;
    z-index: 3;
    cursor: pointer;

    &:after {
        content: '';
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        background: url('../img/play-orange.svg') no-repeat center center;
        background-size: 100% 100%;
        opacity: 0;
        transition: opacity 0.2s ease;
    }

    span {
        @include v-hidden();
    }

    html.desktop .gallery-popup__video-preview:hover &:after {
        opacity: 1;
    }
}

.gallery-popup__close-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 64rem;
    height: 64rem;
    position: absolute;
    right: 20rem;
    top: 10rem;
    z-index: 3;
    color: #fff;
    overflow: hidden;
    padding: 0;
    border: 0;
    background: none;
    transition: color 0.2s ease;

    @media (max-width: $screen-s) {
        top: 0;
        right: 0;
    }

    html.desktop &:hover {
        color: var(--color-orange);
    }

    span {
        @include v-hidden();
    }

    svg {
        width: 34rem;
        height: 18rem;
    }
}

.gallery-popup__navigation {
    position: absolute;
    bottom: 20rem;
    left: 50%;
    margin-left: -48rem;
    width: 96rem;
    display: flex;

    @media (max-width: $screen-m) {
        bottom: 10rem;
    }

    @media (max-width: $screen-s) {
        display: none;
    }
}

.gallery-popup__prev,
.gallery-popup__next {
    display: block;
    width: 48rem;
    height: 48rem;
    position: relative;
    overflow: hidden;
    border: 0;
    padding: 0;
    background: none;
    transition: color 0.2s ease;
    color: #fff;
    z-index: 3;

    html.desktop &:hover {
        color: var(--color-orange);
    }

    &:disabled {
        opacity: 1;
        color: #C6C7CB;
        cursor: default;
    }
    
    span {
        @include v-hidden();
    }

    svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
