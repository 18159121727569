.sponsors {
    margin-top: 100rem;

    @media (max-width: $screen-m) {
        margin-top: 80rem;
    }

    @media (max-width: $screen-s) {
        margin-top: 60rem;
    }
}

.sponsors__inner {
    @include wrap();   
}

.sponsors__title {
    margin-top: 0;
}

.sponsors__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 16rem;
    margin-top: 30rem;

    @media (min-width: $screen-l) {
        grid-template-columns: repeat(6, 1fr);
    }

    @media (max-width: $screen-m) {
        gap: 12rem;
        margin-top: 20rem;
    }

    @media (max-width: $screen-s) {
        grid-template-columns: 1fr 1fr;
    }
}

.sponsors__grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
    aspect-ratio: 2.07;
    background: var(--color-platina);
    border-radius: 20rem;
}
