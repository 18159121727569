.menu {
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 2;
    left: 0;
    top: 0;
    visibility: hidden;
    transition: all 0s ease 0.5s;
    --delay: 0.8s;
    --delay-menu: 0.3s;
    overflow: hidden;

    html._has-open-menu & {
        visibility: visible;
        transition: all 0s ease;
    }
}

.menu__inner {
    position: relative;
    z-index: 2;
    pointer-events: none;

    @media (max-width: $screen-m) {
        height: 100dvh;
        pointer-events: auto;
    }
}

.menu__btn-close {
    position: relative;
    overflow: hidden;

    span {
        @include v-hidden();
    }

    i {
        margin: 0;
    }
}

.menu__scrollable {
    padding-top: 120rem;
    position: relative;
    z-index: 2;
    box-sizing: border-box;

    @media (max-width: $screen-m) {
        padding-top: 76rem;
        overflow-y: auto;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 2;
    }

    html:not(.mobile) & {
        @include scrollbar();
    }
}

.menu__content {
    @include wrap();
    opacity: 0;
    transition: opacity 0s linear var(--delay);

    @media (max-width: $screen-m) {
        padding-bottom: 60rem;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        min-height: 100%;
    }

    @media (max-width: $screen-s) {
        padding-bottom: 60rem;
    }

    html._has-open-menu & {
        opacity: 1;
        transition: opacity 0s linear;
    }

    > * {
        max-width: 300rem;

        @media (max-width: $screen-m) {
            max-width: unset;
        }
    }

    a {
        pointer-events: auto;
    }
}

.menu__list {
    display: block;
    list-style-type: none;
    margin: 0;
    padding: 0;
    font-size: 20rem;

    @media (max-width: $screen-m) {
        text-align: center;
    }

    a:not(.btn) {
        color: var(--color-dark);

        html.desktop &:hover {
            color: var(--color-orange);
        }

        span {
            color: var(--color-orange);
        }
    }

    li {
        margin-bottom: 20rem;
        position: relative;
        opacity: 0;
        transform: translateY(15rem);
        transition: opacity 0.2s ease,
                    transform 0s linear 0.2s;

        &:last-child {
            margin-bottom: 0;
        }

        &._is-active {

            a:not(.btn) {
                color: var(--color-grey);

                span {
                    color: currentColor
                }
            }
        }

        > span {
            color: var(--color-grey);
        }
    }

    html._has-open-menu & {
        
        li {
            opacity: 1;
            transform: translateY(0);
            transition: all 0.25s ease;
        }

        li:nth-child(1) {
            transition-delay: calc(var(--delay-menu) + 0.05s);
        }

        li:nth-child(2) {
            transition-delay: calc(var(--delay-menu) + 0.1s);
        }

        li:nth-child(3) {
            transition-delay: calc(var(--delay-menu) + 0.15s);
        }

        li:nth-child(4) {
            transition-delay: calc(var(--delay-menu) + 0.2s);
        }

        li:nth-child(5) {
            transition-delay: calc(var(--delay-menu) + 0.25s);
        }

        li:nth-child(6) {
            transition-delay: calc(var(--delay-menu) + 0.3s);
        }

        li:nth-child(6) ~ li {
            transition-delay: calc(var(--delay-menu) + 0.35s);
        }
    }
}

.menu__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    backdrop-filter: blur(5px);
    transition: all 0.4s ease-in-out;
    background: rgba(0,0,0,0.6);
    opacity: 0;
    will-change: opacity;

    @media (max-width: $screen-m) {
        backdrop-filter: unset;
    }

    html._has-open-menu & {
        opacity: 1;
    }
}

.menu__top {
    margin-top: auto;
    margin-bottom: auto;
}

.menu__footer {
    margin-top: 80rem;
    opacity: 0;
    transform: translateY(15rem);
    position: relative;
    transition: opacity 0.1s ease,
                transform 0s linear 0.1s;

    @media (max-width: $screen-m) {
        text-align: center;
        margin-top: 50rem;
    }
    
    html._has-open-menu & {
        opacity: 1;
        transform: translateY(0);
        transition: all 0.2s ease calc(var(--delay-menu) + 0.4s);
    }

    .btn {

        html.desktop &:hover {

            i {
                color: #fff;
            }
        }

        i {
            color: var(--color-orange);
            transition: inherit;
        }
    }
}

.menu__circle {
    position: absolute;
    height: 154%;
    aspect-ratio: 1;
    background: #fff;
    transform: translateX(5vw) scale(0);
    z-index: 1;
    border-radius: 50%;
    pointer-events: auto;
    transition: all 0.4s ease-in-out;
    transform-origin: left top;
    top: 30rem;
    left: 0;
    will-change: top, transform;

    @media (max-width: $screen-m) {
        height: 200vmax;
        width: 200vmax;
        top: 10rem;
        transform: translateX(7vw) scale(0);
    }

    html._has-open-menu & {
        top: -40%;
        transform: translateX(-25%) scale(1);

        @media (max-width: $screen-m) {
            transition: all 0.6s ease-in-out;
        }
    }
}

.menu__register-link {
    margin-top: 10rem;
    display: none;

    @media (max-width: $screen-m) {
        display: inline-flex;
    }
}
