.files {
    display: grid;
    gap: 16rem;
    margin: 40rem 0;

    @media (max-width: $screen-m) {
        margin: 20rem 0;
    }
}

.files--2 {
    grid-template-columns: 1fr 1fr;

    @media (max-width: $screen-s) {
        grid-template-columns: 1fr;
    }
}

.file {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background: var(--color-platina);
    border-radius: 12rem;
    padding: 40rem 24rem 30rem 24rem;
    box-sizing: border-box;
    min-height: 230rem;
    color: var(--color-orange);
    font-size: 20rem;

    @media (min-width: $screen-l) {
        min-height: 250rem;
    }

    @media (max-width: $screen-m) {
        min-height: 200rem;
    }

    @media (max-width: $screen-s) {
        padding: 30rem 10rem 30rem 20rem;
        min-height: 170rem;
        border-radius: 12rem;
    }

    html.desktop &:hover {
        color: var(--color-orange);
    }
}

.file__name {
    display: block;
    margin-bottom: 40rem;
    font-size: 20rem;
    font-variation-settings: 'wght' 600;
    color: var(--color-dark);
    transition: color 0.2s ease;

    @media (min-width: $screen-l) {
        font-size: 24rem;
    }

    @media (max-width: $screen-m) {
        font-size: 18rem;
    }

    @media (max-width: $screen-s) {
        font-size: 16rem;
        margin-bottom: 20rem;
    }

    html.desktop .file:hover & {
        color: var(--color-orange);
    }
}

.file__ext {
    display: flex;
    gap: 4rem;
    align-items: center;
    font-size: 14rem;
    font-variation-settings: 'wght' 500;

    span {
        text-decoration: underline;
        transition: text-decoration-color 0.2s ease;
    }

    html.desktop .file:hover & {

        span {
            text-decoration-color: transparent;
        }
    }
}

.file__icon {
    display: block;
    width: 24rem;
    height: 24rem;
    position: relative;
    top: -1rem;

    svg {
        width: 100%;
        height: 100%;
    }
}
