.page-home {

    &._is-preloaded {

        .header {
            top: 0;
            opacity: 1;
        }
    }
    
    body {
        @include net();
    }

    .header {
        background: transparent;
        top: -76rem;
        opacity: 0;
        transition: top 1s ease 3.4s,
                    opacity 1s ease 3.4s,
                    background-color 0.1s ease;
    }

    &._is-scrolled {

        .header {
            background: #fff;
            opacity: 1;
            top: 0;
            transition: background-color 0.1s ease;
        }
    }

    .grey-block {
        padding-top: 1px;
        padding-bottom: 160rem;
        position: relative;
        z-index: 2;
        margin-top: -20rem;
    }

    .filter__inner-right {

        @media (max-width: $screen-m) {
            width: auto;
        }

        @media (max-width: $screen-s) {
            display: none;
        }
    }
    
    .hp-events__link-desktop {

        @media (max-width: $screen-s) {
            display: none;
        }
    }
}

.hp-events__link-mobile,
.hp-rating__link-mobile {
    display: none;

    @media (max-width: $screen-s) {
        display: inline-flex;
        width: 100%;
        margin-top: 20rem;
    }
}
