.contact-cards {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 16rem;

    @media (max-width: $screen-m) {
        margin: 20rem 0;
        gap: 12rem;
    }

    @media (max-width: $screen-s) {
        grid-template-columns: 1fr;
    }
}

.contact-card {
    background: var(--color-platina);
    border-radius: 12rem;
    padding: 44rem 24rem 30rem 24rem;
    min-height: 160rem;
    box-sizing: border-box;

    @media (max-width: $screen-s) {
        min-height: 130rem;
        padding: 30rem 20rem;
    }

    a[href^="tel:"],
    a[href^="mailto:"] {
        color: currentColor;

        html.desktop &:hover {
            color: var(--color-orange);
        }
    }
}

.contact-card__title {
    font-size: 14rem;
    margin-bottom: 10rem;

    @media (min-width: $screen-l) {
        font-size: 18rem;
    }
}

.contact-card__row {
    font-variation-settings: 'wght' 600;
}
