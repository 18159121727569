.header {
    border-bottom: max(1rem, 1px) solid rgba(0,0,0,0.1);
    height: 76rem;
    box-sizing: border-box;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    background: #fff;
    z-index: 9;
    transition: background-color 0.1s ease;
}

.header__inner {
    @extend .wrap;
    display: flex;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    position: relative;
}

.header__left {
    position: relative;
    z-index: 3;
}

.header__burger {
    background: #fff;

    span {
        @media (max-width: $screen-s) {
            display: none;
        }
    }

    i {
        position: relative;
        margin-left: 4rem;

        @media (max-width: $screen-s) {
            margin-left: 0;
            margin-right: 0;
        }

        &:before,
        &:after {
            content: '';
            display: block;
            width: 12rem;
            height: 2px;
            background: currentColor;
            position: absolute;
            top: 50%;
            left: 50%;
            margin-left: -6rem;
            margin-top: -3px;
            transition: margin 0.2s ease,
                        transform 0.2s ease;

            @media (max-width: $screen-s) {
                width: 14rem;
                margin-left: -7rem;
            }
        }

        &:after {
            margin-top: 1px;
        }

        html._has-open-menu & {

            &:before {
                margin-top: -1px;
                transform: rotate(-27deg);
            }

            &:after {
                margin-top: -1px;
                transform: rotate(27deg);
            }
        }
    }
}

.header__logo {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 148rem;
    height: 30rem;
    margin: -15rem 0 0 -74rem;
}

.header__logo-link {
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    pointer-events: none;

    span {
        @include v-hidden();
    }
}

.header__logo-ball {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    color: var(--color-dark);
    transition: color 0.2s ease,
                transform 0.25s ease,
                opacity 0.25s ease;

    html.desktop .header__logo-link:hover & {
        color: var(--color-orange);
    }

    html._is-scrolled & {
        transform: translateY(-100%);
        opacity: 0;
    }

    svg {
        display: block;
        width: 33rem;
        height: 26rem;
        pointer-events: auto;
    }
}

.header__logo-text {
    display: flex;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    opacity: 0;
    transition: transform 0.25s ease,
                opacity 0.25s ease;

    html._is-scrolled & {
        transform: translateY(-100%);
        opacity: 1;
    }

    svg {
        display: block;
        width: 148rem;
        height: 30rem;
        pointer-events: auto;

        @media (max-width: $screen-m) {
            width: 98rem;
            height: 28rem;
        }
    }
}

.header__right {
    display: flex;
    gap: 12rem;
}

.header__register-link {
    @media (max-width: $screen-m) {
        display: none !important;
    }
}
