.ticker {
    margin-top: 100rem;

    @media (max-width: $screen-m) {
        margin-top: 80rem;
    }

    @media (max-width: $screen-s) {
        margin-top: 60rem;
    }
}

.ticker__inner {
    @include wrap();

    @media (max-width: $screen-s) {
        width: 100vw;
    }
}

.ticker__line {
    background: var(--color-orange);
    color: #fff;
    height: 112rem;
    border-radius: 20rem;
    overflow: hidden;
    font-size: 48rem;
    font-variation-settings: 'wght' 500;
    text-transform: uppercase;
    line-height: 1;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;

    @media (max-width: $screen-m) {
        height: 80rem;
        font-size: 32rem;
    }

    @media (max-width: $screen-s) {
        border-radius: 0;
    }

    html.desktop &:hover {
        background: var(--color-dark);
        color: #fff;
    }
}

.ticker__line-inner {
    display: flex;
    animation: ticker 15s linear infinite;
    white-space: nowrap;
    // will-change: transform;

    div {
        // display: inline;
        flex-shrink: 0;
        position: relative;
        white-space: nowrap;
    }
}

@keyframes ticker {
    0% { transform: translateX(0) }
    100% { transform: translateX(-25%) }
}
