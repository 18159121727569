.input-wrap {
    height: 40rem;
    position: relative;

    .input-text {
        z-index: 1;
        width: 100%;
    }

    button {
        position: absolute;
        z-index: 2;
        right: 0;
        height: 100%;
        width: 40rem;
        background: transparent;
        padding: 0;
        border: 0;
    }
}

.input-wrap--search {

    .input-text {
        padding-right: 40rem;
    }

    button {
        transition: color 0.2s ease;
        color: currentColor;

        html.desktop &:hover {
            color: var(--color-orange);
        }

        svg {
            display: block;
            width: 24rem;
            height: 24rem;
            color: currentColor;
            position: absolute;
            left: 50%;
            top: 50%;
            margin: -12rem 0 0 -12rem;
        }

        span {
            @include v-hidden();
        }
    }
}

.input-text {
    border: max(1rem, 1px) solid var(--color-grey-light);
    border-radius: 12rem;
    height: 40rem;
    box-sizing: border-box;
    background: #fff;
    padding: 0 12rem;
    color: var(--color-dark);
    font-size: 14rem;
    transition: all 0.2s ease;
    position: relative;

    html.desktop &:hover:not(:focus) {
        border-color: var(--color-grey);
    }

    &::placeholder {
        color: var(--color-grey);
    }

    &:focus {
        border-color: var(--color-orange);
        outline: 0;
    }
}

.input-text--tr {
    background: transparent;
}
