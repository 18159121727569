// Default outline for :focus state
@mixin focused() {
    // outline: 1px dotted #212121;
    outline: 1px dotted currentColor;
    outline: 5px auto -webkit-focus-ring-color;
}


// Customized native scrollbar
@mixin scrollbar() {
    // Firefox
    // scrollbar-width: thin;
    // scrollbar-color: #fff #000;

    // Webkit
    // &::-webkit-scrollbar {
    //     width: 12px;
    //     height: 12px;
    //     background: #000;
    // }

    // &::-webkit-scrollbar-thumb {
    //     border-radius: 8px;
    //     background-color: #fff;
    // }
}


// Invisible, but available for keybord and screen readers
@mixin v-hidden() {
    position: absolute;
    overflow: hidden;
    margin: -1px;
    width: 1px;
    height: 1px;
    top: 0;
    left: 0;
    clip: rect(0 0 0 0);
}

@mixin wrap() {
    margin-left: auto;
    margin-right: auto;
    width: 94vw;

    @media (max-width: $screen-m) {
        width: 92vw;
    }

    @media (max-width: $screen-s) {
        width: 88vw;
    }

    @media (min-width: $screen-xl) {
        width: 1800rem;
    }
}

@mixin grid() {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16rem;

    @media (min-width: $screen-l) {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }

    @media (max-width: $screen-m) {
        grid-template-columns: 1fr 1fr;
        gap: 12rem;
    }

    @media (max-width: $screen-s) {
        grid-template-columns: 1fr;
    }
}

@mixin net() {
    background: #fff url('../img/net.svg') repeat top center;
    background-size: calc(128 * 100vw / 1440) auto;

    @media (max-width: $screen-m) {
        background-size: calc(200 * 100vw / 1440) auto;
    }

    @media (max-width: $screen-s) {
        background-size: calc(440 * 100vw / 1440) auto;
    }
}
