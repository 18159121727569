.select {
    display: inline-flex;
    position: relative;
}

.ss-main {
    height: 52rem;
    border-radius: 12rem !important;
    padding: 0 20rem 0 12rem;
    border: max(1rem, 1px) solid var(--color-grey-light);
    font-size: 14rem;
    align-items: center;
    transition: all 0.2s ease;
    color: var(--color-dark);

    @media (max-width: $screen-m) {
        height: 40rem;
        padding-left: 10rem;
        padding-right: 14rem;
    }

    &:focus {
        @include focused();
        box-shadow: none;
    }

    html.desktop &:hover {
        border-color: var(--color-grey);
    }

    &.ss-open-below {
        color: var(--color-orange);
    }

    .ss-arrow {
        flex-shrink: 0;
        width: 14rem;
        height: 14rem;
        margin: 0 0 0 44rem;

        @media (max-width: $screen-m) {
            margin-left: 10rem;
        }

        path {
            stroke-linecap: unset;
            stroke-width: 12;
            stroke: currentColor;
        }
    }

    .select--compact & {
        height: 40rem;
        padding-right: 14rem;

        .ss-arrow {
            margin-left: 10rem;
        }
    }

    .select--tr & {
        background: transparent;
    }
}

.ss-content {
    min-width: 200rem !important;
    transform: translateY(0);
    opacity: 0;
    border: 0;
    box-shadow: 0rem 10rem 30rem 0rem rgba(0, 0, 0, 0.10);
    border-radius: 12rem !important;
    visibility: hidden;
    opacity: 0;
    top: 0;
    transition: opacity 0.1s ease,
                transform 0s linear 0.2s,
                visibility 0s linear 0.2s;

    &.ss-open-below {
        transform: translateY(10rem);
        visibility: visible;
        opacity: 1;
        transition: opacity 0.2s ease,
                    transform 0.2s ease;
    }
}

.ss-list {
    position: relative;
    padding: 20rem 0;

    html:not(.mobile) & {
        scrollbar-width: thin;
        scrollbar-color: var(--color-orange) #fff;

        &::-webkit-scrollbar {
            width: 12rem;
            height: 12rem;
            background: #fff;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 6rem;
            background-color: var(--color-orange);
        }
    }
}

.ss-option {
    padding: 10rem 20rem !important;
    transition: color 0.2s ease;
    color: var(--color-dark) !important;
    font-size: 14rem;

    &:hover,
    &.ss-highlighted,
    &:not(.ss-disabled).ss-selected {
        background: none !important;
        color: var(--color-orange) !important;
    }
}

.ss-placeholder {
    color: currentColor !important;
    padding: 0 !important;
}

.ss-single {
    margin: 0 !important;
}
