.tag {
    display: inline-flex;
    align-items: center;
    height: 30rem;
    padding: 0 10rem;
    box-sizing: border-box;
    line-height: 31rem;
    font-size: 12rem;
    border-radius: 12rem;
    color: var(--color-dark);
    font-variation-settings: 'wght' 600;
    background: #F1F3F6;
    white-space: nowrap;
}

.tag--date {
    background: #F1F3F6;
    color: var(--color-dark);
}

.tag--standard {
    background: var(--color-yellow-faded);
    color: var(--color-yellow);
}

.tag--light {
    background: var(--color-green-faded);
    color: var(--color-green);
}

.tag--hard {
    background: var(--color-red-faded);
    color: var(--color-red);
}

.tag--orange {
    background: var(--color-orange-faded);
    color: var(--color-orange);
}

.tag--gold {
    background: var(--gradient-gold);
    color: #fff;
}

.tag--silver {
    background: var(--gradient-silver);
    color: #fff;
}

.tag__star {
    width: 9rem;
    height: 9rem;
    margin-right: 4rem;
    margin-top: -5px;
    margin-bottom: -5px;
}

.tag--orange-2 {
    background: var(--color-orange);
    color: #fff;
}

.tag--brick {
    background: rgba(241, 133, 75, 0.1);
    color: #F1854B;
}

.tag--status {
    height: 24rem;
    line-height: 25rem;
    border-radius: 12rem;
    background: var(--color-orange);
    color: #fff;

    @media (max-width: $screen-s) {
        height: 20rem;
        line-height: 21rem;
        padding: 0 6rem;
        font-size: 10rem;
    }
}

.tag--blur {
    background: rgba(255,255,255,0.2);
    color: #fff;
    backdrop-filter: blur(5px);
}
