.participants-item {
    position: relative;
    background: #fff;
    border-radius: 12rem;
}

.participants-item__inner {
    @extend .players-item__inner;
    justify-content: flex-start;
}

.participants-item__cell {
    flex-shrink: 0;
    box-sizing: border-box;
    padding-right: 12rem;

    @media (max-width: $screen-s) {
        font-size: 11rem;
    }

    &:nth-child(1) {
        display: none;
    }

    &:nth-child(2) {
        width: 33%;
        padding-left: 30rem;

        @media (max-width: $screen-m) {
            padding-left: 20rem;
            width: 38%;
        }

        @media (max-width: $screen-s) {
            padding-left: 12rem;
            width: 55%;
        }
    }

    &:nth-child(3) {
        width: 14%;

        @media (max-width: $screen-m) {
            width: 24%;
        }

        @media (max-width: $screen-s) {
            width: 25%;
            padding-top: 1rem;
        }
    }

    &:nth-child(4) {
        width: 17%;
        font-size: 12rem;

        @media (max-width: $screen-m) {
            width: 15%;
        }

        @media (max-width: $screen-s) {
            width: 20%;
            padding-top: 2rem;
        }
    }

    &:nth-child(5) {
        width: 20%;

        @media (max-width: $screen-m) {
            display: none;
        }
    }

    &:nth-child(6) {
        width: 16%;

        @media (max-width: $screen-m) {
            width: 23%;
            padding-right: 20rem;
        }

        @media (max-width: $screen-s) {
            width: 100%;
            padding-left: 12rem;
            padding-right: 12rem;
            padding-top: 8rem;
            text-align: center;
            display: flex;
            flex-direction: column-reverse;
            gap: 12rem;

            .btn {
                width: 100%;
                background: #fff;
                color: var(--color-orange);
            }
        }

        > b {
            display: block;
            margin-bottom: 6rem;

            @media (max-width: $screen-m) {
                font-size: 12rem;
            }

            @media (max-width: $screen-s) {
                font-size: 11rem;
            }
        }
    }

    .tag {
        @media (max-width: $screen-s) {
            font-size: 10rem;
            padding: 0 8rem;
            height: 20rem;
            line-height: 21rem;
        }
    }
}

.participants-item__person {
    @extend .rating-item__person;
}

.participants-item__person-img {
    @extend .rating-item__person-img;
}

.participants-item__person-name {
    @extend .rating-item__person-name;
}

.participants-item__link {
    @extend .players-item__link;

    html.desktop &:hover {
        border-color: var(--color-orange);

        ~ .participants-item__inner {
            color: var(--color-orange);
        }
    }

    span {
        @include v-hidden();
    }
}

.participants-item--double {

    .participants-item__inner {
        padding-top: 20rem;
        padding-bottom: 20rem;
        pointer-events: auto;

        @media (max-width: $screen-m) {
            padding-top: 10rem;
            padding-bottom: 10rem;
        }

        @media (max-width: $screen-s) {
            padding-top: 12rem;
        }

        + .participants-item__inner {
            padding-top: 0;
            margin-top: -26rem;

            @media (max-width: $screen-m) {
                margin-top: -10rem;
            }

            @media (max-width: $screen-s) {
                margin-top: 0;
                padding-bottom: 0;
            }

            .participants-item__cell {

                &:nth-child(6) {
                    display: none;

                    @media (max-width: $screen-s) {
                        display: block;
                    }
                }
            }
        }
    }

    .participants-item__cell {

        &:nth-child(6) {

            @media (max-width: $screen-s) {
                display: none;
            }
        }
    }

    .participants-item__person-name {
        padding: 8rem 0;

        @media (max-width: $screen-s) {
            padding: 4rem 0 0 0;
        }

        span {
            display: inline;
        }
    }

    .participants-item__person-img {
        width: 40rem;
        height: 40rem;
        border: 3rem solid #fff;
        margin-right: 35rem;
        margin-left: 11rem;

        @media (max-width: $screen-m) {
            width: 30rem;
            height: 30rem;
            margin-left: 5rem;
            margin-right: 17rem;
        }

        @media (max-width: $screen-s) {
            width: 24rem;
            height: 24rem;
            margin-left: 0;
            margin-right: 10rem;
            border: 0;
        }
    }
}

.participants-item__status {
    
    @media (max-width: $screen-m) {
        font-size: 12rem;
    }

    @media (max-width: $screen-s) {
        border-top: 1rem solid rgba(0,0,0,0.1);
        padding: 10rem 0;
        font-size: 11rem;
    }
}


.participants--results {
    .participants-item__inner {

        + .participants-item__inner {

            @media (max-width: $screen-s) {
                padding-bottom: 10rem;
            }
        }
    }

    .participants-item__cell {

        &:nth-child(1) {
            display: block;
            width: 7%;
            padding-left: 30rem;
            padding-right: 0;

            @media (max-width: $screen-m) {
                width: 10%;
                padding-left: 20rem;
            }

            @media (max-width: $screen-s) {
                padding-left: 12rem;
                width: 13%;
                padding-top: 4rem;
            }
        }

        &:nth-child(2) {
            width: 40%;
            padding-left: 0 !important;

            @media (max-width: $screen-m) {
                width: 50%;
            }
    
            @media (max-width: $screen-s) {
                width: 50%;
            }
        }

        &:nth-child(3) {
    
            @media (max-width: $screen-m) {
                width: 20%;
            }
    
            @media (max-width: $screen-s) {
                width: 22%;
                padding-top: 1rem;
            }
        }

        &:nth-child(4) {
            width: 13%;
        }

        &:nth-child(6) {
            display: none !important;
        }
    }
}
