.about-s2 {
    height: calc(100svh - 76rem);
    position: relative;

    @media (max-width: $screen-m) {
        height: auto;
    }
}

.about-s2__title {
    color: #fff;
    position: relative;
    z-index: 3;
    margin-left: 17%;
    margin-bottom: 0;
    width: 41%;
    letter-spacing: -0.01em;

    @media (min-width: $screen-l) {
        margin-top: 120rem;
    }

    @media (max-width: $screen-m) {
        margin-left: 0;
        width: 66%;
        font-size: 28rem;
    }

    @media (max-width: $screen-s) {
        width: 100%;
    }
}

.about-s2__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(109deg, #CCC1B7 0%, #7B9FB2 0.01%, #E0D4C4 93.08%);

    &:after {
        content: '';
        display: block;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        position: absolute;
        z-index: 2;
        background: #000;
        opacity: 0.4;
        pointer-events: none;
        @include net();
    }
}

.about-s2__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: $screen-m) {
        
    }

    @media (max-width: $screen-s) {
        display: block;
    }
}

.about-s2__text {
    width: 33%;

    @media (max-width: $screen-m) {
        width: 50%;
    }

    @media (max-width: $screen-s) {
        width: 100%;
    }
}

.about-s2__img {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 57%;
    height: 68%;
    z-index: 1;
    overflow: hidden;

    @media (max-width: $screen-m) {
        display: none;
    }

    img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
    }
}

.about-s2__img-mobile {
    display: none;
    position: relative;
    width: 73vw;
    height: auto;
    aspect-ratio: 1.62;
    margin-left: auto;
    margin-right: -4vw;
    margin-bottom: 20rem;
    margin-top: -50rem;
    flex-shrink: 0;

    @media (max-width: $screen-m) {
        display: block;
    }

    @media (max-width: $screen-s) {
        width: 94vw;
        margin-top: 50rem;
        margin-right: 0;
    }

    img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
    }
}
