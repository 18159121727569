.slider {
    margin: 20rem 0;
    --swiper-preloader-color: var(--color-orange);
    --swiper-pagination-fraction-color: var(--color-dark) !important;

    .swiper-slide {
        background-color: var(--color-platina);

        html.desktop & {
            cursor: grab;
        }
    }

    .swiper-lazy-preloader {
        z-index: 1;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        z-index: 2;
    }
}

.slider__inner {
    aspect-ratio: 16 / 9;
    border-radius: 12rem;
    overflow: hidden;
    background-color: var(--color-platina);
}

.slider__bottom {
    display: flex;
    gap: 60rem;
    justify-content: flex-end;
    margin-top: 10rem;

    @media (max-width: $screen-m) {
        gap: 40rem;
    }

    @media (max-width: $screen-s) {
        gap: 10rem
    }
}

.slider__caption {
    width: 100%;
    font-variation-settings: 'wght' 300;
    margin-top: 14rem;
}

.slider__navigation {
    width: 96rem;
    display: flex;
    user-select: none;
}

.slider__controls {
    width: 200rem;
    display: flex;
    flex-shrink: 0;
    gap: 20rem;
    justify-content: flex-end;

    @media (max-width: $screen-m) {
        width: 180rem;
    }

    @media (max-width: $screen-s) {
        width: 96rem;
    }
}

.slider__prev,
.slider__next {
    display: block;
    width: 48rem;
    height: 48rem;
    position: relative;
    overflow: hidden;
    border: 0;
    padding: 0;
    background: none;
    transition: color 0.2s ease;
    color: var(--color-dark);

    html.desktop &:hover {
        color: var(--color-orange);
    }

    &:disabled {
        opacity: 1;
        color: #C6C7CB;
        cursor: default;
    }
    
    span {
        @include v-hidden();
    }

    svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

.slider__pagination {
    line-height: 49rem;
    text-align: right;
    font-variation-settings: 'wght' 300;
    user-select: none;

    @media (max-width: $screen-m) {
        line-height: 50rem;
    }

    @media (max-width: $screen-s) {
        display: none;
    }
}
