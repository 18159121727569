.rating__filter {
    margin: 30rem 0;

    .filter__inner {
        display: flex;
        justify-content: space-between;

        @media (max-width: $screen-m) {
            flex-wrap: wrap;
        }

        @media (max-width: $screen-s) {
            margin-bottom: 20rem;
        }
    }

    .tabs {
        @media (max-width: $screen-s) {
            width: 100%;
            justify-content: space-between;
        }
    }
}

.filter__inner-right {
    display: flex;
    gap: 12rem;

    .input-wrap--search {
        min-width: 240rem;
    }

    @media (max-width: $screen-m) {
        width: 100%;

        .input-wrap--search {
            width: 50%;
        }

        .select {
            width: 50%;
        }
    }

    @media (max-width: $screen-s) {
        flex-wrap: wrap;

        .input-wrap--search {
            width: 100%;
        }

        .select {
            width: 100%;
        }
    }
}

.rating__head {
    display: flex;
    margin-bottom: 20rem;
    font-variation-settings: 'wght' 300;
    text-align: left;

    @media (min-width: $screen-l) {
        font-size: 16rem;
    }

    @media (max-width: $screen-s) {
        font-size: 10rem;
        margin-bottom: 6rem;
    }

    > div {
        flex-shrink: 0;
        box-sizing: border-box;
        padding-right: 12rem;

        @media (max-width: $screen-s) {
            padding-right: 10rem;
        }

        &:nth-child(1) {
            width: 12%;
            padding-left: 10rem;

            @media (max-width: $screen-m) {
                width: 15%;
            }

            @media (max-width: $screen-s) {
                width: 15%;
            }
        }

        &:nth-child(2) {
            width: 26%;
            padding-left: 90rem;

            @media (max-width: $screen-m) {
                width: 36%;
                padding-left: 56rem;
            }

            @media (max-width: $screen-s) {
                padding-left: 34rem;
                width: 51%;
            }
        }

        &:nth-child(3) {
            width: 18%;

            @media (max-width: $screen-m) {
                width: 19%;
            }

            @media (max-width: $screen-s) {
                display: none;
            }
        }

        &:nth-child(4) {
            width: 20%;

            @media (max-width: $screen-m) {
                display: none;
            }
        }

        &:nth-child(5) {
            width: 10%;

            @media (max-width: $screen-m) {
                width: 15%;
            }

            @media (max-width: $screen-s) {
                width: 18%;
            }
        }

        &:nth-child(6) {
            width: 18%;

            @media (max-width: $screen-m) {
                width: 15%;
            }

            @media (max-width: $screen-s) {
                width: 16%;
            }
        }
    }
}

.rating__body,
.rating__body > .tabs-target._is-active {
    display: grid;
    grid-template-columns: 1fr;
    gap: 10rem;
    text-align: left;

    @media (max-width: $screen-s) {
        gap: 6rem;
    }
}
