.footer {
    margin-top: auto;
    padding-bottom: 3vw;

    .page-grey & {
        background: #fff;
    }

    @media (max-width: $screen-m) {
        padding-bottom: 4vw;
    }

    @media (max-width: $screen-s) {
        padding-bottom: 6vw;
    }
}

.footer__inner {
    background: var(--color-platina);
    border-radius: 12rem;
    padding-top: 50rem;
    --padding: 30rem;

    @media (min-width: $screen-l) {
        --padding: 40rem;
    }

    @media (max-width: $screen-m) {
        --padding: 20rem;
        padding-top: 32rem;
    }

    @media (max-width: $screen-s) {
        --padding: 0;
        padding-top: 40rem;
    }
}

.footer__top,
.footer__bottom {
    display: grid;
    gap: 0 16rem;
    grid-template-columns: repeat(12, 1fr);

    @media (max-width: $screen-s) {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-left: 20rem;
        padding-right: 20rem;
    }
}

.footer__logo {
    grid-column: span 3;
    padding-left: var(--padding);

    @media (min-width: $screen-l) {
        grid-column: span 2;
    }

    @media (max-width: $screen-m) {
        grid-column: span 8;
        margin-bottom: 30rem;
    }

    @media (max-width: $screen-s) {
        margin-bottom: 36rem;
    }
}

.footer__logo-svg {
    width: 200rem;
    aspect-ratio: 5.43;
    color: var(--color-orange);

    @media (max-width: $screen-m) {
        width: 148rem;
    }

    @media (max-width: $screen-s) {
        width: 200rem;
    }
}

.footer__nav {
    grid-column: span 4;
    display: grid;
    gap: inherit;
    grid-template-columns: 1fr 1fr;
    font-size: 14rem;

    @media (min-width: $screen-l) {
        grid-column: span 6;
        grid-template-columns: 1fr 1fr 1fr;
    }

    @media (max-width: $screen-m) {
        grid-column: span 8;
        grid-template-columns: 1fr 1fr;
        padding-left: var(--padding);
    }

    @media (max-width: $screen-s) {
        display: none;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;

        li {
            margin-bottom: 16rem;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    a {
        color: var(--color-dark);
        font-variation-settings: 'wght' 500;

        html.desktop &:hover {
            color: var(--color-orange);
        }
    }
}

.footer__contacts {
    padding-right: var(--padding);
    grid-column: span 5;
    text-align: right;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: flex-end;

    @media (min-width: $screen-l) {
        grid-column: span 4;
    }

    @media (max-width: $screen-m) {
        grid-column-start: 9;
        grid-column-end: 13;
        grid-row-start: 1;
        grid-row-end: 3;
    }

    @media (max-width: $screen-s) {
        align-items: center;
    }

    a:not(.btn) {
        font-size: 24rem;
        white-space: nowrap;

        @media (max-width: $screen-m) {
            font-size: 20rem;
        }
    }

    .btn {
        margin-top: 16rem;
    }
}

.footer__bottom {
    position: relative;
    align-items: center;
    font-size: 14rem;
    margin-top: 43rem;
    padding-bottom: 17rem;
    padding-top: 17rem;

    @media (max-width: $screen-m) {
        margin-top: 24rem;
    }

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: var(--padding);
        right: var(--padding);
        top: 0;
        border-top: max(1rem, 1px) solid rgba(0,0,0,0.1);

        @media (max-width: $screen-s) {
            content: none;
        }
    }
}

.footer__copyright {
    padding-left: var(--padding);
    grid-column: span 2;

    @media (max-width: $screen-m) {
        grid-column: span 3;
    }

    @media (max-width: $screen-s) {
        order: 2;
        margin-top: 24rem;
    }
}

.footer__links {
    grid-column: span 8;
    text-align: center;

    @media (max-width: $screen-m) {
        grid-column: span 6;
    }

    @media (max-width: $screen-s) {
        order: 1;
        border-top: max(1rem, 1px) solid rgba(0,0,0,0.1);
        padding-top: 24rem;
        width: 100%;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        list-style: none;
        justify-content: center;
        gap: 10rem 30rem;
        margin: 0;
        padding: 0;

        @media (max-width: $screen-m) {
            gap: 10rem 20rem;
        }

        @media (max-width: $screen-s) {
            flex-direction: column;
            gap: 15rem 20rem;
        }
    }

    a {
        font-variation-settings: 'wght' 500;
        color: var(--color-dark);

        html.desktop &:hover {
            color: var(--color-orange);
        }
    }
}

.footer__madeby {
    grid-column: span 2;
    padding-right: var(--padding);
    display: flex;
    justify-content: flex-end;

    @media (max-width: $screen-m) {
        grid-column: span 3;
    }

    @media (max-width: $screen-s) {
        width: 100%;
        order: 3;
        margin-top: 20rem;
        padding-top: 24rem;
        border-top: max(1rem, 1px) solid rgba(0,0,0,0.1);
        justify-content: center;
    }
}

.footer__madeby-link {
    display: block;
    width: 130rem;
    aspect-ratio: 4.357;
    background: url('../img/ctm.svg') no-repeat right top;
    background-size: contain;
    position: relative;
    transition: opacity 0.2s ease;
    overflow: hidden;

    html.desktop &:hover {
        opacity: 0.7;
    }
    
    span {
        @include v-hidden();
    }
}
