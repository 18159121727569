.pagination {
    margin-top: 30rem;
    display: flex;
    justify-content: center;
    align-items: center;
    user-select: none;
    font-size: 16rem;
    font-variation-settings: 'wght' 600;

    @media (max-width: $screen-s) {
        font-size: 14rem;
    }

    ul {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;

        a, span {
            display: block;
            min-width: 40rem;
            height: 40rem;
            line-height: 40rem;
            text-align: center;
            border-radius: 12rem;
            transition: color 0.2s ease;
            box-sizing: border-box;
            padding: 0 5rem;

            @media (max-width: $screen-s) {
                min-width: 36rem;
                height: 36rem;
                line-height: 36rem;
            }
        }

        a {
            color: var(--color-dark);

            html.desktop &:hover {
                color: var(--color-orange)
            }
        }
    }
}

.pagination__current {
    background: var(--color-orange);
    color: #fff;
}

.pagination__prev,
.pagination__next {
    display: flex;
    width: 40rem;
    height: 40rem;
    position: relative;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    border-radius: 12rem;
    color: var(--color-dark);
    transition: color 0.2s ease;

    @media (max-width: $screen-s) {
        width: 36rem;
        height: 36rem;
    }

    span {
        @include v-hidden();
    }

    svg {
        display: block;
        width: 24rem;
        height: 24rem;
    }
}

a.pagination__prev,
a.pagination__next {
    html.desktop &:hover {
        color: var(--color-orange);
    }
}

span.pagination__prev,
span.pagination__next {
    color: #CED3DF;
}
