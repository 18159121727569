.progress-slider {
    margin-top: 40rem;

    @media (max-width: $screen-m) {
        margin-top: 20rem;
    }

    .swiper {
        overflow: visible;
    }

    .swiper-slide {
        height: auto;
    }
}

.progress-slider__inner {

    @media (max-width: $screen-m) {
        overflow: visible;
    }
}

.progress-slider__bottom {
    display: flex;
    gap: 20rem;
    justify-content: flex-end;
    margin-top: 10rem;

    @media (max-width: $screen-s) {
        margin-top: 0;
    }
}

.progress-slider__navigation {
    width: 96rem;
    display: flex;
    user-select: none;
}

.progress-slider__controls {
    display: flex;
    flex-shrink: 0;
    gap: 20rem;
    justify-content: flex-end;
}

.progress-slider__prev,
.progress-slider__next {
    display: block;
    width: 48rem;
    height: 48rem;
    position: relative;
    overflow: hidden;
    border: 0;
    padding: 0;
    background: none;
    transition: color 0.2s ease;
    color: var(--color-dark);

    html.desktop &:hover {
        color: var(--color-orange);
    }

    &:disabled {
        opacity: 1;
        color: #C6C7CB !important;
        cursor: default;
        pointer-events: none;
    }

    &.swiper-button-lock {
        display: none;
    }
    
    span {
        @include v-hidden();
    }

    svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

.progress-slider__progress {
    width: 100%;
    height: max(1rem, 1px) !important;
    background: var(--color-grey-light);
    position: relative;
    margin-top: 24rem;

    .swiper-pagination-progressbar-fill {
        background: var(--color-orange);
        height: max(3rem, 3px);
        margin-top: min(-1rem, -1px);
    }
}
