.page-rating {

    .content {
        margin-bottom: 0;
    }

    .content__inner {
        margin-bottom: 100rem;

        @media (max-width: $screen-m) {
            margin-bottom: 80rem;
        }

        @media (max-width: $screen-s) {
            margin-bottom: 60rem;
        }
    }

    .other {
        padding-top: 1px;
        background: #fff;
        border-radius: 12rem 12rem 0 0;
        padding-bottom: 40rem;
    }
}
