.tooltip {
    display: inline-block;
    width: 24rem;
    height: 24rem;
    position: relative;
}

.tooltip__btn {
    width: 24rem;
    height: 24rem;
    background: transparent url('../img/info.svg') no-repeat 0 0;
    background-size: 100% auto;
    cursor: pointer;
    border: 0;
    padding: 0;
    position: relative;
    overflow: hidden;

    span {
        @include v-hidden;
    }
}

.tooltip__content {
    position: absolute;
    bottom: 0;
    width: 256rem;
    bottom: 24rem;
    left: 50%;
    margin-left: -128rem;
    padding-bottom: 6rem;
    display: none;
}

.tooltip__content-inner {
    box-sizing: border-box;
    background: #fff;
    border-radius: 12rem;
    box-shadow: 0px 10px 30px 0px rgba(0, 0, 0, 0.10);
    padding: 24rem 20rem;
}

.tooltip__content-title {
    color: var(--color-orange);
    margin-bottom: 10rem;
    font-size: 14rem;
}

.tooltip__content-text {
    font-size: 14rem;
}

.tooltip__content-footer {
    margin-top: 20rem;
}


.tippy-box {
    background: transparent;
    width: 256rem;
}

.tippy-arrow {
    display: none;
}

.tippy-content {
    padding: 0;
    color: var(--color-dark);
}
