.hero {
    position: fixed;
    width: 100%;
    min-height: calc(100svh + 20rem);
    left: 0;
    top: 0;
    overflow: hidden;
    @include net();
    background-color: #f6f6f6 !important;
    background-position: top center !important;
    transition: opacity 0s;
    --func: ease-in-out;

    &._is-hidden {
        visibility: hidden;
        opacity: 0;
    }

    &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: #fff;
        z-index: 1;
        pointer-events: none;
    }
}

.hero__inner {
    @include wrap;
    display: flex;
    flex-direction: column;
    min-height: calc(100svh + 20rem);
    position: relative;
    z-index: 3;
    padding-bottom: 60rem;
    padding-top: 94rem;
    box-sizing: border-box;

    @media (min-width: $screen-l) {
        padding-top: 95rem;
        padding-bottom: 110rem;
    }

    @media (max-width: $screen-m) {
        padding-top: 90rem;
        padding-bottom: 70rem;
    }

    @media (max-width: $screen-s) {
        padding-top: 84rem;
        padding-bottom: calc(6vw + 20rem);
    }
}

.hero__top {
    margin-bottom: 80rem;

    @media (max-width: $screen-s) {
        margin-bottom: 50rem;
    }
}

.hero__h1 {
    font-size: 16rem;
    font-variation-settings: 'wght' 500;
    margin: 16rem 0 0 35%;
    opacity: 0;
    transform: translateX(-7%);
    // color: #fff;

    @media (min-width: ($screen-m + 1)) {
        @include v-hidden;
    }

    @media (max-width: $screen-s) {
        font-size: 14rem;
        margin-left: 25%;
        transform: translateX(-10%);
    }
}

.hero__ubt {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 110rem;
    line-height: 1;
    text-transform: uppercase;
    font-variation-settings: 'wght' 800;
    // color: #fff;
    letter-spacing: -0.02em;

    @media (min-width: $screen-l) {
        font-size: 152rem;
    }

    @media (max-width: $screen-m) {
        font-size: 82rem;
        flex-direction: column;
    }

    @media (max-width: $screen-s) {
        font-size: 52rem;
    }
}

.hero__ubt-word {
    display: block;
    align-self: flex-start;
    min-height: 0;
    position: relative;
    opacity: 0;
    will-change: transform, opacity;

    > div {
        position: relative;

        @media (max-width: $screen-m) {
            align-self: unset;
        }
    }

    &:nth-child(1) {
        transform: translateX(40%);

        @media (max-width: $screen-m) {
            width: 100%;
            transform: translateX(10%);
        }

        @media (max-width: $screen-s) {
            transform: translateX(15%);
        }

        div {

            &:before,
            &:after {
                content: attr(data-before);
                position: absolute;
                left: calc(100% + 1.5em);
                font-size: 16rem;
                line-height: 1;
                font-variation-settings: 'wght' 500;
                bottom: 2.2em;
                letter-spacing: 0;
                text-transform: none;
                white-space: nowrap;
                opacity: 0;

                @media (min-width: $screen-l) {
                    font-size: 22rem;
                }

                @media (max-width: $screen-m) {
                    content: none !important;
                }
            }

            &:after {
                content: attr(data-after);
                bottom: 0.9em;
            }
        }
    }

    &:nth-child(2) {
        transform: translateX(-40%);

        @media (max-width: $screen-m) {
            transform: translateX(-10%);
            width: 100%;
            text-align: right;
            margin-top: -15rem;
        }

        @media (max-width: $screen-s) {
            margin-top: -5rem;
            transform: translateX(-20%);
        }
    }

    &:nth-child(3) {
        margin-left: 50%;
        margin-top: -0.15em;
        transform: translateX(-50%);

        @media (max-width: $screen-m) {
            margin-left: 35%;
            margin-top: 0;
            transform: translateX(-30%);
        }

        @media (max-width: $screen-s) {
            margin-left: 25%;
            margin-top: -5rem;
            transform: translateX(-25%);
        }
    }
}

.hero__ball {
    width: 1360rem;
    height: 1360rem;
    background: url('../img/ball.webp') no-repeat top center;
    background-size: 100% auto;
    position: absolute;
    top: 360rem;
    left: 50%;
    margin-left: -680rem;
    z-index: 2;
    opacity: 0;
    transform: translateY(300rem);

    @media (min-width: $screen-l) {
        top: 450rem;
        width: 1900rem;
        height: 1900rem;
        margin-left: -950rem;
    }

    @media (max-width: $screen-m) {
        width: 910rem;
        height: 910rem;
        margin-left: -455rem;
        top: 550rem;
    }

    @media (max-width: $screen-s) {
        width: 640rem;
        height: 640rem;
        margin-left: -320rem;
        top: 360rem;
    }
}

.hero__down {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 180rem;
    height: 180rem;
    border-radius: 50%;
    box-sizing: border-box;
    border: 2rem solid #fff;
    background: none;
    backdrop-filter: blur(15px);
    overflow: hidden;
    color: var(--color-orange);
    opacity: 0;

    @media (max-width: $screen-m) {
        width: 164rem;
        height: 164rem;
    }

    @media (max-width: $screen-s) {
        display: none;
    }

    html.desktop &:hover {
        color: #000;
    }

    span {
        @include v-hidden;
    }

    svg {
        width: 44%;
        height: 44%;
    }
}

.hero__calendar-link {
    display: block;
    width: 350rem;
    border-radius: 20rem;
    background: #fff;
    position: relative;
    padding: 20rem 20rem 90rem 20rem;
    box-sizing: border-box;
    transform: translateY(-40rem);
    opacity: 0;

    @media (max-width: $screen-m) {
        width: 320rem;
    }

    @media (max-width: $screen-s) {
        width: 100%;
    }

    html.desktop &:hover {

        svg {
            right: 15rem;
        }
    }

    span {
        display: block;
        font-size: 24rem;
        font-variation-settings: 'wght' 700;
        text-transform: uppercase;

        @media (max-width: $screen-m) {
            font-size: 20rem;
        }
    }

    svg {
        width: 50rem;
        height: 50rem;
        position: absolute;
        bottom: 20rem;
        right: 20rem;
        transition: right 0.2s ease;

        @media (max-width: $screen-m) {
            width: 40rem;
            height: 40rem;
            right: 15rem;
            bottom: 15rem;
        }
    }
}

.hp-hero__bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 3;
    pointer-events: none;
}

.hero__bottom {
    margin-top: auto;
    display: flex;
    justify-content: space-between;
    position: relative;
}


@media (min-width: ($screen-m + 1)) and (max-width: ($screen-l - 1)) and (min-aspect-ratio: 2.3 / 1) {

    .hero {
        position: relative;
        margin-top: -76rem;

        + div {
            margin-top: -20rem;
        }
    }

    .hp-hero__bg {
        display: none;
    }
}

@media (min-width: $screen-l) and (min-aspect-ratio: 2.5 / 1) {

    .hero {
        position: relative;
        margin-top: -76rem;

        + div {
            margin-top: -20rem;
        }
    }

    .hp-hero__bg {
        display: none;
    }
}

@media (min-width: ($screen-s + 1)) and (max-width: $screen-m) and (min-aspect-ratio: 1.2 / 1) {

    .hero {
        position: relative;
        margin-top: -76rem;

        + div {
            margin-top: -20rem;
        }
    }

    .hp-hero__bg {
        display: none;
    }
}

@media (max-width: $screen-s) and (min-aspect-ratio: 1 / 1.5) {

    .hero {
        position: relative;
        margin-top: -76rem;

        + div {
            margin-top: -20rem;
        }
    }

    .hp-hero__bg {
        display: none;
    }
}

html._is-preloaded {

    .hero {

        &:after {
            opacity: 0;
            transition: opacity 1.2s var(--func) 2.7s;
        }
    }

    .hero__ubt {
        color: #000;
        transition: color 2s var(--func) 2.8s;
    }

    .hero__ubt-word {
        transform: translateX(0);
        opacity: 1;
        transition: transform 2s var(--func) 0.5s,
                    opacity 2s var(--func) 0.5s;

        &:nth-child(1) {

            div {

                &:before,
                &:after {
                    opacity: 1;
                    transition: opacity 0.8s var(--func) 2s;
                }
            }
        }
    }

    .hero__h1 {
        opacity: 1;
        transform: translateX(0);
        color: #000;
        transition: transform 2s var(--func) 0.5s,
                    opacity 2s var(--func) 0.5s,
                    color 2s var(--func) 2.8s;
    }

    .hero__ball {
        transform: translateY(0);
        opacity: 1;
        transition: all 1s ease 3.4s;
    }

    .hero__calendar-link {
        opacity: 1;
        transform: translateY(0);
        transition: transform 1s ease 3.4s,
                    opacity 1s ease 3.4s,
                    color 0.2s ease;
    }

    .hero__down {
        opacity: 1;
        animation: bounce 4s infinite 6s;
        transform-origin: bottom;
        transition: opacity 1s ease 3.4s,
                    color 0.2s ease;
    }
}

@keyframes bounce {
    0% { transform: translateY(0) scale(1, 1) }
    10% { transform: translateY(-25%) scale(1, 1) }
    13% { transform: translateY(10%) scale(1, 1) }
    15% { transform: translateY(10%) scale(1, 0.8) }
    22% { transform: translateY(-10%) scale(1, 1) }
    27% { transform: translateY(0%) scale(1, 0.9) }
    32% { transform: translateY(0%) scale(1, 1) }
}
