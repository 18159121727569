h1, h2, h3, h4, h5, h6,
.h1, .h2, .h4, .h5, .h6 {
    word-wrap: break-word;
	overflow-wrap: break-word;

    sup {
        display: inline-block;
        vertical-align: top;
        background: var(--color-orange);
        color: #fff;
        height: 2.14em;
        padding: 0 0.7em;
        border-radius: 0.86em;
        line-height: 2.21em;
        font-size: 0.3em;
        left: -0.2em;
        top: -0.7em;
    }
}

h1, .h1 {
    font-size: 48rem;
    line-height: 1.2;
    font-weight: 500;
    font-variation-settings: 'wght' 500;
    margin: 30rem 0 20rem 0;

    &:first-child {
        margin-top: 0;
    }

    @media (min-width: $screen-l) {
        font-size: 64rem;
    }

    @media (max-width: $screen-m) {
        font-size: 32rem;
        margin-top: 20rem;
    }

    @media (max-width: $screen-s) {
        font-size: 28rem;
    }
}

h2, .h2 {
    font-size: 48rem;
    line-height: 1.2;
    font-weight: 500;
    font-variation-settings: 'wght' 500;
    margin: 100rem 0 20rem 0;

    @media (min-width: $screen-l) {
        font-size: 64rem;
    }

    @media (max-width: $screen-m) {
        font-size: 32rem;
        margin-top: 80rem;
    }

    @media (max-width: $screen-s) {
        font-size: 28rem;
        margin-top: 60rem;
    }
}

h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
    font-size: 36rem;
    line-height: 1.2;
    font-weight: 500;
    font-variation-settings: 'wght' 500;
    margin: 100rem 0 20rem 0;

    @media (min-width: $screen-l) {
        font-size: 48rem;
    }

    @media (max-width: $screen-m) {
        font-size: 28rem;
        margin-top: 80rem;
    }

    @media (max-width: $screen-s) {
        font-size: 28rem;
        margin-top: 60rem;
    }
}

p {
    margin: 1em 0;
}

.text {
    font-weight: 300;
    font-variation-settings: 'wght' 300;
    line-height: 1.4;

    @media (max-width: $screen-s) {
        // hyphens: auto;
    }

    * {
        word-wrap: break-word;
        overflow-wrap: break-word;
    }

    > *:first-child {
        // margin-top: 0 !important;
    }

    > *:last-child {
        // margin-bottom: 0;
    }

    h1, h2, h3, h4, h5, h6 {

        + * {
            margin-top: 0 !important;
        }
    }

    img {
        display: block;
        max-width: 100%;
    }

    a:not([class]) {
        color: var(--color-orange);
        text-decoration: underline;
        transition: text-decoration-color 0.2s ease;

        html.desktop &:hover {
            color: var(--color-orange);
            text-decoration-color: transparent;
        }
    }

    b, strong {
        font-weight: normal;
        font-variation-settings: 'wght' 600;
    }

    i {
        font-style: normal;
    }

    em {
        font-style: normal;
        font-weight: normal;
        font-variation-settings: 'wght' 600;
        color: var(--color-orange);
        font-size: 20rem;

        @media (min-width: $screen-l) {
            font-size: 24rem;
        }

        @media (max-width: $screen-m) {
            font-size: 16rem;
        }
    }

    table {
        border: 1px solid var(--color-dark);
        margin: 2em 0;
        width: 100%;

        th, td {
            border: 1px solid var(--color-dark);
            text-align: left;
            padding: 8rem;
        }

        th {
            background: var(--color-platina);
        }

        th {
            font-weight: 500;
            font-variation-settings: 'wght' 500;
        }
    }

    ul, ol {
        margin: 20rem 0;
        padding: 0;
        list-style: none;

        li {
            margin-bottom: 14rem;
            display: block;
            padding-left: 32rem;
            position: relative;

            @media (min-width: $screen-l) {
                margin-bottom: 16rem;
            }

            @media (max-width: $screen-m) {
                margin-bottom: 12rem;
            }
        }
    }

    ul {

        > li {

            &:before {
                content: '';
                display: block;
                width: 24rem;
                height: 24rem;
                background: url('../img/list.svg') no-repeat 0 0;
                background-size: 100% auto;
                position: absolute;
                left: 1rem;
                top: -1rem;

                @media (min-width: $screen-l) {
                    width: 32rem;
                    height: 32rem;
                    left: -6rem;
                    top: -2rem;
                }

                @media (max-width: $screen-m) {
                    top: -3rem;
                }
            }
        }
    }

    ol {
        counter-reset: ol;
        
        > li {
            
            &:before {
                counter-increment: ol;
                content: counter(ol) '.';
                display: block;
                position: absolute;
                left: 5rem;
                top: 0;
                color: var(--color-orange);
                font-variation-settings: 'wght' 600;

                @media (min-width: $screen-l) {
                    left: 0;
                }
            }
        }
    }
}
