.pro-slider {
    background: var(--color-dark);
    padding: 90rem 0 130rem 0;
    border-radius: 20rem;
    margin-top: -60rem;
    color: #fff;
    position: relative;
    z-index: 2;

    @media (min-width: $screen-l) {
        padding-top: 130rem;
    }

    @media (max-width: $screen-m) {
        padding-top: 80rem;
        padding-bottom: 60rem;
    }

    @media (max-width: $screen-s) {
        padding: 60rem 0;
    }

    h2 {
        margin-top: 0;
    }

    .swiper {
        overflow: visible;
    }

    .swiper-slide {
        width: auto;
        transition-property: all;
        height: 436rem;
        user-select: none;

        @media (min-width: $screen-l) {
            height: 590rem;
        }

        @media (max-width: $screen-m) {
            height: 320rem;
        }

        @media (max-width: $screen-s) {
            height: 88vw;
        }
    }

    .swiper-lazy-preloader {
        z-index: 0;
    }
}

.pro-slider__inner {
    position: relative;
}

.pro-slider__link-desktop {
    position: absolute;
    top: 10rem;
    right: 0;

    @media (min-width: $screen-l) {
        top: 22rem;
    }

    @media (max-width: $screen-m) {
        top: -2rem;
    }
    
    @media (max-width: $screen-s) {
        display: none;
    }
}

.pro-slider__link-mobile {
    display: none;

    @media (max-width: $screen-s) {
        display: inline-flex;
        width: 100%;
        margin-top: 20rem;
    }
}

.pro-slider__slider {
    margin-top: 30rem;

    @media (min-width: $screen-l) {
        margin-top: 45rem;
    }

    @media (max-width: $screen-m) {
        margin-top: 20rem;
    }
}

.pro-slider__slider-slide-link {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 324rem;
    height: 324rem;
    transition: all 0.4s ease;
    padding: 20rem 10rem 10rem 10rem;
    box-sizing: border-box;
    position: relative;

    @media (min-width: $screen-l) {
        width: 438rem;
        height: 438rem;
    }

    @media (max-width: $screen-m) {
        width: 320rem;
        height: 320rem;
    }

    @media (max-width: $screen-s) {
        width: 88vw;
        height: 88vw;
    }

    .swiper-slide-next + .swiper-slide & {
        width: 436rem;
        height: 436rem;

        @media (min-width: $screen-l) {
            width: 590rem;
            height: 590rem;
        }

        @media (max-width: $screen-m) {
            width: 320rem;
            height: 320rem;
        }

        @media (max-width: $screen-s) {
            width: 88vw;
            height: 88vw;
        }
    }
}

.pro-slider__slider-slide-img {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    border-radius: 12rem;
    overflow: hidden;

    &:before {
        content: '';
        display: block;
        top: 1rem;
        left: 1rem;
        right: 1rem;
        bottom: 1rem;
        position: absolute;
        z-index: 1;
        background: var(--color-grey-light) url('../img/dummy_male.svg') no-repeat center center;
        background-size: 33% auto;
        opacity: 0.3;
        border-radius: 12rem;
    }

    &._female:before {
        background-image: url('../img/dummy_female.svg')
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        margin: 0;
        transition: transform 1s;
        will-change: transform;
        position: relative;
        z-index: 2;

        html.desktop .pro-slider__slider-slide-link:hover & {
            transform: scale(1.06);
        }
    }
}

.pro-slider__slider-slide-top {
    position: relative;
    z-index: 2;

    .tag {
        color: #fff;
        background: #000;
        background: rgba(20, 29, 46, 0.50);
        backdrop-filter: blur(5px);
    }
}

.pro-slider__slider-slide-bottom {
    position: relative;
    z-index: 2;
    background: rgba(20, 29, 46, 0.50);
    backdrop-filter: blur(5px);
    padding: 10rem;
    border-radius: 12rem;
    color: #fff;
}

.pro-slider__slider-slide-name {
    max-width: 280rem;
    font-size: 20rem;
    font-variation-settings: 'wght' 600;

    @media (min-width: $screen-l) {
        font-size: 24rem;
        max-width: 320rem;
    }

    @media (max-width: $screen-m) {
        font-size: 16rem;
    }
}

.pro-slider__slider-slide-details {
    height: 24rem;
    display: flex;
    align-items: center;
    font-size: 14rem;
    font-variation-settings: 'wght' 500;
    margin-top: 10rem;

    svg {
        width: 24rem;
        height: 24rem;
        display: block;
        position: relative;
        transition: all 0.2s ease;
        left: 0;

        html.desktop .pro-slider__slider-slide-link:hover & {
            left: 4rem;
        }
    }
}

.pro-slider__slider-bottom {
    display: flex;
    justify-content: space-between;
    margin-top: 10rem;
    width: 436rem;
    margin-left: 682rem;
    position: relative;

    @media (min-width: $screen-l) {
        width: 590rem;
        margin-left: 912rem;
        margin-top: 16rem;
    }

    @media (max-width: $screen-m) {
        gap: 40rem;
        margin-left: 0;
        width: 100%;
    }
}

.pro-slider__slider-navigation {
    width: 96rem;
    display: flex;
    user-select: none;
}

.pro-slider__slider-prev,
.pro-slider__slider-next {
    display: block;
    width: 48rem;
    height: 48rem;
    position: relative;
    overflow: hidden;
    border: 0;
    padding: 0;
    background: none;
    transition: color 0.2s ease;
    color: var(--color-orange);

    html.desktop &:hover {
        color: #fff;
    }

    &:disabled {
        opacity: 1;
        color: #C6C7CB;
        cursor: default;
    }
    
    span {
        @include v-hidden();
    }

    svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}

.pro-slider__slider-pagination {
    line-height: 49rem;
    font-variation-settings: 'wght' 300;
    user-select: none;
    font-size: 14rem;

    @media (max-width: $screen-m) {
        line-height: 50rem;
    }
}
