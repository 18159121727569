.tabs {
    display: flex;
    background: #fff;
    list-style: none;
    margin: 0;
    padding: 0;
    border-radius: 12rem;

    a {
        display: block;
        padding: 10rem;
        height: 40rem;
        border-radius: 8rem;
        font-variation-settings: 'wght' 500;
        box-sizing: border-box;
        font-size: 14rem;
        line-height: 21rem;
        transition: all 0.2s ease;
        user-select: none;

        &._is-active {
            background: var(--color-orange);
            color: #fff !important;
            pointer-events: none;
        }

        ._long {
            @media (max-width: $screen-s) {
                display: none;
            }
        }

        ._short {
            @media (min-width: ($screen-s + 1)) {
                display: none;
            }
        }
    }
}

.tabs-target {
    display: none;

    &._is-active {
        display: block;
    }
}
