.players-item {
    position: relative;
}

.players-item__inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    z-index: 2;
    padding: 10rem 0;
    transition: color 0.2s ease;
    border-radius: 12rem;
    color: var(--color-dark);
    transition: all 0.2s ease;
    pointer-events: none;

    @media (max-width: $screen-m) {
        padding: 10rem 0;
        flex-wrap: wrap;
        gap: 10rem 0;
    }

    @media (max-width: $screen-s) {
        padding: 12rem 0;
        align-items: flex-start;
    }

    b {
        font-weight: normal;
        font-variation-settings: 'wght' 600;
    }

    a,
    button {
        pointer-events: auto;
    }
}

.players-item__cell {
    flex-shrink: 0;
    box-sizing: border-box;
    padding-right: 12rem;

    @media (max-width: $screen-s) {
        font-size: 11rem;
    }

    &:nth-child(1) {
        width: 30%;
        padding-left: 30rem;

        @media (max-width: $screen-m) {
            padding-left: 20rem;
            width: 42%;
        }

        @media (max-width: $screen-s) {
            padding-left: 12rem;
            width: 60%;
        }
    }

    &:nth-child(2) {
        width: 14%;

        @media (max-width: $screen-m) {
            width: 16%;
        }

        @media (max-width: $screen-s) {
            width: 20%;
        }
    }

    &:nth-child(3) {
        width: 14%;

        @media (max-width: $screen-m) {
            width: 16%;
        }

        @media (max-width: $screen-s) {
            width: 20%;
        }
    }

    &:nth-child(4) {
        width: 30%;

        @media (max-width: $screen-m) {
            display: none;
        }
    }

    &:nth-child(5) {
        width: 12%;
        text-align: right;
        padding-right: 30rem;

        @media (max-width: $screen-m) {
            width: 26%;
            padding-right: 20rem;
        }

        @media (max-width: $screen-s) {
            width: 100%;
            padding-left: 12rem;
            padding-right: 12rem;

            .btn {
                width: 100%;
            }
        }
    }

    .tag {
        @media (max-width: $screen-s) {
            font-size: 10rem;
            padding: 0 8rem;
            height: 20rem;
            line-height: 21rem;
        }
    }
}

.players-item__person {
    @extend .rating-item__person;
}

.players-item__person-img {
    @extend .rating-item__person-img;
}

.players-item__person-name {
    @extend .rating-item__person-name;
}

.players-item__link {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    box-sizing: border-box;
    border-radius: 12rem;
    border: 2rem solid transparent;
    z-index: 1;
    overflow: hidden;
    transition: border-color 0.2s ease;
    background: #fff;

    html.desktop &:hover {
        border-color: var(--color-orange);

        ~ .players-item__inner {
            color: var(--color-orange);
        }
    }

    span {
        @include v-hidden();
    }
}
