.page-event {
    
    .text-content {
        grid-column-start: 2;
        grid-column-end: 8;
    }

    .columns {
        align-items: start;

        @media (max-width: $screen-m) {
            display: block;
        }
    }

    .grey-block {
        padding-top: 1px;
        padding-bottom: 40rem;
        margin-top: 100rem;

        .load-more {
            margin-top: 30rem;
        }
    }

    .gallery {
        background: #fff;
    }
}

.event-info {
    background: var(--color-platina);
    grid-column-start: 8;
    grid-column-end: 12;
    box-sizing: border-box;
    padding: 22rem;
    border-radius: 20rem;
    height: auto;

    @media (min-width: $screen-l) {
        grid-column-start: 9;
    }

    @media (max-width: $screen-m) {
        margin-top: 30rem;
    }

    @media (max-width: $screen-s) {
        width: 100vw;
        position: relative;
        left: 50%;
        margin-left: -50vw;
        padding: 20rem 6vw;
    }
}

.event-info__inner {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24rem 16rem;

    @media (max-width: $screen-m) {
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24rem 12rem;
    }

    @media (max-width: $screen-s) {
        grid-template-columns: 1fr 1fr;
    }
}

.event-info__row {
    font-size: 14rem;
    grid-column: span 2;

    @media (max-width: $screen-m) {
        grid-column: span 1;
    }

    @media (max-width: $screen-s) {
        grid-column: span 2;
    }

    > span {
        display: block;
    }

    > b {
        display: block;
        font-weight: 400;
        font-variation-settings: 'wght' 600;
        margin-top: 6rem;
    }
}

.event-info__row--half {
    grid-column: span 1;
}

.event-info__row--full {
    @media (max-width: $screen-m) {
        grid-column: span 3;
    }
}

.event-info__tags {
    margin-top: 10rem;
    display: flex;
    flex-wrap: wrap;
    gap: 10rem;
}

.event-info__footer {
    background: #fff;
    border-radius: 12rem;
    padding: 20rem;
    grid-column: span 2;

    @media (max-width: $screen-m) {
        grid-column: span 3;
    }

    .btn {
        margin-top: 14rem;
        width: 100%;
    }

    .tooltip__content-footer {
        .btn {
            margin-top: 0;
            width: auto;
        }
    }

    > b {
        font-weight: 400;
        font-variation-settings: 'wght' 500;
        font-size: 14rem;
        color: var(--color-orange);
    }
}

.event-info__footer-row {
    font-size: 12rem;
    display: flex;
    margin-bottom: 6rem;

    .tooltip {
        position: relative;
        margin-left: 4rem;
        margin-top: -4rem;
    }

    > b {
        font-weight: 400;
        font-variation-settings: 'wght' 500;
        color: var(--color-orange);
        margin-left: auto;
        padding-left: 20rem;
        white-space: nowrap;
    }
}
