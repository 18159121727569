.player__top {
    aspect-ratio: 6.43;
    background: var(--color-platina) url('../img/player-pattern.svg') top center repeat;
    background-size: 103rem auto;
    border-radius: 12rem;

    @media (max-width: $screen-m) {
        aspect-ratio: 5.05;
        background-size: 70rem auto;
    }

    @media (max-width: $screen-s) {
        width: 100%;
        height: 140rem;
    }
}

.player-info {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    gap: 16rem;

    @media (max-width: $screen-m) {
        display: block;
    }
}

.player-info__left {
    grid-column: span 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.player-info__img {
    width: 154rem;
    height: 154rem;
    border-radius: 50%;
    overflow: hidden;
    background: var(--color-grey-light) url('../img/dummy_male.svg') no-repeat center center;
    background-size: 33% auto;
    flex-shrink: 0;
    margin-top: -82rem;
    border: 10rem solid #fff;
    margin-bottom: 4rem;
    flex-shrink: 0;

    @media (min-width: $screen-l) {
        width: 200rem;
        height: 200rem;
        margin-bottom: 10rem;
        margin-top: -110rem;
    }

    @media (max-width: $screen-m) {
        width: 112rem;
        height: 112rem;
        margin-top: -66rem;
    }

    @media (max-width: $screen-s) {
        width: 75rem;
        height: 75rem;
        border: 6rem solid #fff;
        margin-top: -44rem;
    }

    &._female {
        background-image: url('../img/dummy_female.svg')
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.player-info__id {
    margin-top: 14rem;
    font-size: 12rem;

    .player-info__img + & {
        margin-top: 4rem;
    }

    @media (max-width: $screen-s) {
        
        br {
            display: none;
        }
    }
}

.player-info__right {
    grid-column: span 9;
}

.player-info__top {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 40rem;

    @media (max-width: $screen-m) {
        margin-top: 10rem;
    }
}

.player-info__top-left {
    max-width: 600rem;

    @media (min-width: $screen-l) {
        max-width: 800rem;
    }

    @media (max-width: $screen-m) {
        max-width: unset;
        text-align: center;
        width: 100%;

        h1 {
            margin-bottom: 14rem;
        }
    }
}

.player-info__top-right {
    margin-left: 40rem;
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    gap: 16rem;
    min-height: 0;
    align-items: flex-start;
    margin-top: 10rem;

    @media (max-width: $screen-m) {
        display: none;
    }
}

.player-info__tags {
    display: flex;
    flex-wrap: wrap;
    gap: 10rem;

    @media (max-width: $screen-m) {
        justify-content: center;
    }
}

.player-info__numbers {
    display: grid;
    grid-template-columns: repeat(9, 1fr);
    gap: 16rem;
    margin-top: 40rem;

    @media (min-width: $screen-l) {
        margin-top: 60rem;
    }

    @media (max-width: $screen-m) {
        gap: 12rem;
        grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: $screen-s) {
        grid-template-columns: 1fr 1fr;
        gap: 24rem 12rem;
    }

    > div {
        grid-column: span 2;

        @media (max-width: $screen-m) {
            grid-column: unset !important;
        }

        &:last-child {
            grid-column: span 3;
        }

        span {
            display: block;
            font-size: 16rem;

            @media (max-width: $screen-s) {
                font-size: 12rem;
            }
        }

        b {
            display: block;
            font-weight: 400;
            font-variation-settings: 'wght' 500;
            font-size: 48rem;
            margin-top: 8rem;

            @media (max-width: $screen-m) {
                font-size: 32rem;
                margin-top: 0;
            }
        }
    }
}

.player-info__bottom {
    display: none;

    @media (max-width: $screen-m) {
        display: flex;
        gap: 12rem;
        margin-top: 50rem;
    }

    @media (max-width: $screen-s) {
        margin-top: 40rem;
        flex-wrap: wrap;
    }

    .btn {
        width: 100%;
    }
}

.player-info__about {
    margin-top: 60rem;

    @media (min-width: $screen-l) {
        margin-top: 80rem;
    }

    em {
        color: currentColor;
    }
}

.player--pro {

    .player-info__numbers {

        @media (min-width: $screen-l) {
            width: 900rem;
            grid-template-columns: 1fr 1fr 1fr;

            > div {
                grid-column: span 1;

                &:nth-child(2) {
                    display: none;
                }
            }
        }

        @media (max-width: $screen-m) {
            grid-template-columns: 1fr 1fr 1fr;

            > div {
                grid-column: span 1;

                &:nth-child(2) {
                    display: none;
                }
            }
        }

        @media (max-width: $screen-s) {
            grid-template-columns: 1fr 1fr;
        }
    }

    .player-info__about {

        @media (min-width: $screen-l) {
            width: 900rem;
        }
    }
}
