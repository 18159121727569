.autoComplete_wrapper {
    display: inline-block;
    position: relative;
    width: 100%;
}
  
  
.autoComplete_wrapper > ul {
    position: absolute;
    min-width: 200rem;
    max-height: 200rem;
    overflow-y: auto;
    overflow-x: hidden;
    top: 100%;
    left: 0;
    right: 0;
    padding: 10rem 0;
    margin: 10rem 0 0 0;
    background: #fff;
    z-index: 1000;
    outline: none;
    border-radius: 12rem;
    box-shadow: 0rem 10rem 30rem 0rem rgba(0, 0, 0, 0.10);

    html:not(.mobile) & {
        scrollbar-width: thin;
        scrollbar-color: var(--color-orange) transparent;

        &::-webkit-scrollbar {
            width: 12rem;
            height: 12rem;
            background: transparent;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 6rem;
            background-color: var(--color-orange);
        }
    }
}
  
.autoComplete_wrapper > ul > li {
    padding: 0;
    margin-right: 20rem;
    list-style: none;
    text-align: left;
    color: #212121;
    transition: all 0.1s ease-in-out;
    background-color: rgba(255, 255, 255, 1);
    transition: all 0.2s ease;
}
  
.autoComplete_wrapper > ul > li::selection {
    color: rgba(#ffffff, 0);
    background-color: rgba(#ffffff, 0);
}
  
.autoComplete_wrapper > ul > li:hover {
    cursor: pointer;
    color: var(--color-orange);
}
  
.autoComplete_wrapper > ul > li mark {
    background-color: transparent;
    color: rgba(255, 122, 122, 1);
    font-weight: bold;
}
  
.autoComplete_wrapper > ul > li mark::selection {
    color: rgba(#ffffff, 0);
    background-color: rgba(#ffffff, 0);
}
  
.autoComplete_wrapper > ul > li[aria-selected="true"] {
    color: var(--color-orange);
}
  
.autocomplete-item {
    font-size: 14rem;
    line-height: 1.2;
}

.autocomplete-item__player {
    display: flex;
    gap: 10rem;
    padding: 10rem 20rem;
}

.autocomplete-item__player-img {
    width: 32rem;
    height: 32rem;
    border-radius: 50%;
    flex-shrink: 0;
    overflow: hidden;
    background: var(--color-grey-light) url('../img/dummy_male.svg') no-repeat center center;
    background-size: 50% auto;

    &._female {
        background-image: url('../img/dummy_female.svg')
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.autocomplete-item__player-name {
    font-variation-settings: 'wght' 500;
    font-size: 14rem;

    span {
        display: block;
        font-size: 12rem;
        color: var(--color-grey);
        margin-top: 2rem;
    }
}
