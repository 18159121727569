:root {
    scroll-behavior: smooth;
}

html {
    margin: 0;
    padding: 0;
    width: 100%;
    -webkit-font-smoothing: antialiased;
    -webkit-text-size-adjust: 100%;
    font-variant-ligatures: none;
    font-family: var(--font-default);
    font-size: var(--rem);
    line-height: 1.3;
    font-weight: 400;
    font-variation-settings: 'wght' 400;
    height: 100%;
    min-height: 320px;
    min-width: 320px;
    color: #000;
    background: #fff;

    &:not(.mobile) {
        @include scrollbar();
    }

    &._has-no-visible-focus {

        * {
            outline: 0 !important;
        }
    }

    &.page-grey {
        background: #f6f6f6;
    }

    @media (min-width: 1921px) {
        // font-size: 0.052vw;
    }
}

body {
    margin: 0;
    padding: 0;
    width: 100%;
    position: relative;
    overflow-x: hidden;
    font-size: 16rem;
    color: #000;

    @media (min-width: $screen-l) {
        font-size: 20rem;
    }

    @media (max-width: $screen-m) {
        font-size: 14rem;
    }
}


::selection {
    color: #fff;
    background: var(--color-orange);
}



// Placeholders
::-webkit-input-placeholder {
    color: rgba(0, 0, 0, 0.4);
    opacity: 1;
}

::-moz-placeholder {
    color: rgba(0, 0, 0, 0.4);
    opacity: 1;
}



// :disabled
:disabled {
    cursor: default;
    pointer-events: none;
    opacity: 0.7;
    user-select: none;
}


// Simple tags
img {
    margin: 0;
}

a {
    text-decoration: none;
    cursor: pointer;
    transition: color 0.2s ease;
    color: var(--color-orange);

    html.desktop &:hover {
        color: #000;
    }

    &[href^="mailto"],
    &[href^="tel"] {
        user-select: text;
    }
}

ul {

    nav & {
        margin: 0;
        padding: 0;
    }
}

button,
input[type="checkbox"],
input[type="radio"] {
    cursor: pointer;
}

textarea,
input[type="tel"],
input[type="text"],
input[type="email"],
input[type="password"],
input:not([type]) {
    -webkit-appearance: none;
}

textarea {

    html.desktop & {
        @include scrollbar();
    }
}

select {
    // appearance: none;
}

fieldset {
    border: 0;
    margin: 0;
    padding: 0;
}

figure,
figcaption {
    margin: 0;
    padding: 0;
}

template {
    display: none;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}
