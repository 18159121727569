.about-targets {
    padding-top: 100rem;

    @media (max-width: $screen-m) {
        padding-top: 80rem;
    }

    @media (max-width: $screen-s) {
        padding-top: 60rem;
    }
}

.about-targets__inner {
    @include wrap();
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 16rem;

    @media (max-width: $screen-m) {
        display: block;
    }
}

.about-targets__title {
    margin-top: 0;

    em {
        font-style: normal;
        color: var(--color-orange);
    }
}

.about-targets__grid {
    display: grid;
    gap: 16rem;
    grid-template-columns: 1fr 1fr;

    @media (max-width: $screen-m) {
        gap: 12rem;
    }

    @media (max-width: $screen-s) {
        grid-template-columns: 1fr;
    }
}

.about-targets__item {
    border-radius: 20rem;
    background: var(--color-platina);
    padding: 30rem 20rem;
    line-height: 1.37;
    min-height: 200rem;
    box-sizing: border-box;

    @media (min-width: $screen-l) {
        padding: 40rem;
    }

    @media (max-width: $screen-m) {
        min-height: unset;
        padding: 20rem;
    }
}

.about-targets__item-title {
    color: var(--color-orange);
    font-variation-settings: 'wght' 600;
    font-size: 20rem;
    margin-bottom: 10rem;

    @media (min-width: $screen-l) {
        font-size: 24rem;
    }

    @media (max-width: $screen-m) {
        font-size: 18rem;
    }

    @media (max-width: $screen-s) {
        font-size: 16rem;
    }
}
