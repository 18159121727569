.other__inner,
.other-news__inner {
    @include wrap();
    position: relative;
}

.news-grid {
    @include grid();

    .news-item {
        min-height: 0;
    }

    .news-item__inner {
        height: 100%;
    }
}

.other__link,
.other-news__link {
    position: absolute;
    top: 10rem;
    right: 0;

    @media (min-width: $screen-l) {
        top: 22rem;
    }

    @media (max-width: $screen-m) {
        top: -2rem;
    }
}
