$screen-s: 599px;
$screen-m: 1099px;
$screen-l: 1600px;
$screen-xl: 2000px;

:root {
    --font-default: 'Onest', Arial, sans-serif;
    --rem: calc(100vw / 1440);
    --color-orange: #FF4200;
    --color-orange-faded: #FF42001A;
    --color-platina: #F6F6F6;
    --color-grey: #828D9E;
    --color-grey-light: #CED3DF;
    --color-dark: #000;
    --color-yellow: #FFAE00;
    --color-yellow-faded: #FBB92C26;
    --color-green: #2DC365;
    --color-green-faded: #2DC3651A;
    --color-red: #F25565;
    --color-red-faded: #F2556526;
    --gradient-silver: linear-gradient(105deg, #909090 9.71%, rgba(157, 157, 157, 0.50) 59.18%);
    --gradient-gold: linear-gradient(105deg, #AC925E 9.71%, #DDC7A1 59.18%, #AC925E 105.53%);

    @media (max-width: $screen-m) {
        --rem: calc(100vw / 768);
    }

    @media (max-width: $screen-s) {
        --rem: calc(100vw / 360);
    }

    @media (min-width: $screen-l) {
        --rem: calc(100vw / 1920);
    }

    @media (min-width: $screen-xl) {
        --rem: 1px;
    }
}
