.about-s4 {
    height: calc(100svh - 76rem);
    position: relative;

    @media (max-width: $screen-m) {
        height: auto;
        aspect-ratio: 1;
    }
}

.about-s4__bg {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    bottom: 0;
    z-index: 1;
    background: linear-gradient(109deg, #CCC1B7 0%, #7B9FB2 0.01%, #E0D4C4 93.08%);
    overflow: hidden;
}

.about-s4__bg-inner {
    position: absolute;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;

    img {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        object-fit: cover;
    }
}
