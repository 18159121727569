.news-item {

    .tag--date {
        background: rgba(0,0,0,0.05);
        font-variation-settings: 'wght' 500;
    }

    .swiper-slide & {
        height: 100%;
    }
}

.news-item__inner {
    padding: 12rem 24rem 30rem 24rem;
    box-sizing: border-box;
    background: var(--color-platina);
    display: flex;
    flex-direction: column;
    color: var(--color-dark);
    border-radius: 20rem;
    height: 100%;
    min-height: 490rem;

    @media (min-width: $screen-l) {
        min-height: 490rem;
    }

    @media (max-width: $screen-m) {
        min-height: 400rem;
    }

    @media (max-width: $screen-s) {
        min-height: 370rem;
    }

    .grey-block & {
        background: #fff;
    }
}

.news-item__img {
    aspect-ratio: 2;
    border-radius: 12rem;
    overflow: hidden;
    position: relative;
    margin-left: -12rem;
    margin-right: -12rem;

    &:before {
        content: '';
        display: block;
        position: absolute;
        left: 1rem;
        top: 1rem;
        right: 1rem;
        bottom: 1rem;
        background: var(--gradient-silver);
        z-index: 1;
        border-radius: inherit;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: relative;
        transition: transform 1s;
        will-change: transform;
        z-index: 2;

        html.desktop .news-item__inner:hover & {
            transform: scale(1.06);
        }
    }
}

.news-item__tags {
    margin-top: 24rem;
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;

    @media (max-width: $screen-m) {
        margin-top: 20rem;
    }
}

.news-item__title {
    font-size: 20rem;
    font-variation-settings: 'wght' 600;
    margin-top: 24rem;
    margin-bottom: 24rem;
    transition: color 0.2s ease;

    @media (min-width: $screen-l) {
        font-size: 24rem;
    }

    @media (max-width: $screen-m) {
        font-size: 18rem;
        margin-top: 20rem;
        margin-bottom: 20rem;
    }

    @media (max-width: $screen-s) {
        font-size: 16rem;
    }

    html.desktop .news-item__inner:hover & {
        color: var(--color-orange);
    }

    .news-item--event & {
        font-size: 32rem;

        @media (max-width: $screen-m) {
            font-size: 24rem;
        }

        @media (max-width: $screen-s) {
            font-size: 20rem;
        }
    }
}

.news-item__details {
    height: 24rem;
    display: flex;
    align-items: center;
    font-size: 14rem;
    font-variation-settings: 'wght' 500;

    svg {
        width: 24rem;
        height: 24rem;
        display: block;
        position: relative;
        transition: all 0.2s ease;
        left: 0;

        html.desktop .news-item__inner:hover & {
            left: 4rem;
        }
    }
}

.news-item__footer {
    margin-top: auto;
}
