.hp-about {
    margin-top: calc(100svh - 76rem);
    position: relative;
    z-index: 2;
    border-radius: 20rem 20rem 0 0;
    background: #fff;
    padding-top: 70rem;
    padding-bottom: 120rem;
    @include net();

    @media (max-width: $screen-m) {
        padding-top: 60rem;
        padding-bottom: 90rem;
    }

    @media (max-width: $screen-s) {
        padding-top: 50rem;
        padding-bottom: 80rem;
    }
}

.hp-about__inner {
    @include wrap();
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    align-items: start;
    gap: 16rem;
    position: relative;
    z-index: 2;

    @media (max-width: $screen-m) {
        gap: 30rem 12rem;
        grid-template-columns: repeat(8, 1fr);
    }

    @media (max-width: $screen-s) {
        grid-template-columns: 1fr;
    }
}

.hp-about__content {
    grid-column: span 6;

    @media (max-width: $screen-m) {
        grid-column: span 5;
    }

    @media (max-width: $screen-s) {
        grid-column: span 1;
    }

    .btn {
        margin-top: 10rem;
    }
}

.hp-about__title {
    font-size: 36rem;
    line-height: 1.3;
    text-transform: uppercase;
    color: #B9BBC0;
    font-weight: 400;
    font-variation-settings: 'wght' 600;
    letter-spacing: -0.01em;
    margin-top: 40rem;
    margin-bottom: 30rem;
    margin-right: -16.6%;

    @media (min-width: $screen-l) {
        font-size: 48rem;
    }

    @media (max-width: $screen-m) {
        margin-right: 0;
        margin-top: 0;
        margin-bottom: 10rem;
        font-size: 24rem;
    }

    @media (max-width: $screen-s) {
        font-size: 22rem;
    }

    b {
        font-weight: 400;
        font-variation-settings: 'wght' 600;
        color: var(--color-orange);
    }
}

.hp-about__video {
    grid-column-start: 9;
    grid-column-end: 13;
    border-radius: 20rem;
    overflow: hidden;
    aspect-ratio: 0.7;

    @media (max-width: $screen-m) {
        grid-column-start: 6;
        grid-column-end: 9;
    }

    @media (max-width: $screen-s) {
        grid-column: span 1;
    }
    
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.hp-about__bottom {
    @include wrap();
    margin-top: 30rem;
    text-align: center;

    @media (max-width: $screen-s) {
        margin-top: 20rem;

        .btn {
            width: 100%;
        }
    }
}

.hp-about__racket {
    position: relative;
    width: 100%;
    height: 810rem;
    z-index: 1;

    @media (min-width: $screen-l) {
        height: 1090rem;
    }

    @media (max-width: $screen-m) {
        height: 490rem;
    }

    @media (max-width: $screen-s) {
        width: 100vw;
        height: auto;
        margin-top: 135rem;
    }
}

.hp-about__history {

    @media (max-width: $screen-s) {
        display: flex;
        overflow-x: auto;
        gap: 12rem;
        padding-left: 6vw;
        padding-right: 6vw;
        width: 100vw;
        box-sizing: border-box;
        position: relative;
        scroll-snap-type: x mandatory;
        scroll-padding: 0 6vw;

        &::-webkit-scrollbar {
            height: 0;
        }
    }
}

.hp-about__history-item {
    box-sizing: border-box;
    padding: 30rem;
    border-radius: 20rem;
    border: max(1rem, 1px) solid var(--color-grey-light);
    background: rgba(0,0,0,0.03);
    backdrop-filter: blur(15px);
    position: absolute;
    left: 50%;
    line-height: 1.4;

    @media (max-width: $screen-m) {
        padding: 20rem 20rem;
        scroll-snap-align: start;
        font-size: 16rem;
    }

    @media (max-width: $screen-s) {
        position: relative;
        top: unset !important;
        left: unset !important;
        flex-shrink: 0;
        width: 240rem !important;
        font-size: 14rem;
        margin: 0 !important;
        backdrop-filter: unset;
        background: #f7f6f6;
    }

    &:nth-child(1) {
        width: 300rem;
        top: 80rem;
        margin-left: 90rem;

        @media (min-width: $screen-l) {
            width: 340rem;
            top: 110rem;
            margin-left: 140rem;
        }

        @media (max-width: $screen-m) {
            width: 260rem;
            top: 42rem;
            margin-left: 57rem;
        }
    }

    &:nth-child(2) {
        width: 340rem;
        top: 405rem;
        margin-left: -516rem;

        @media (min-width: $screen-l) {
            width: 460rem;
            top: 531rem;
            margin-left: -706rem;
        }

        @media (max-width: $screen-m) {
            width: 240rem;
            top: 295rem;
            margin-left: -331rem;
        }
    }

    &:nth-child(3) {
        width: 340rem;
        top: 500rem;
        margin-left: 137rem;

        @media (min-width: $screen-l) {
            width: 460rem;
            top: 650rem;
            margin-left: 167rem;
        }

        @media (max-width: $screen-m) {
            width: 259rem;
            top: 233rem;
            margin-left: 94rem;
        }
    }

    em {
        font-style: normal;
        color: var(--color-orange);
    }
}

.hp-about__animation {
    position: absolute;
    width: 80%;
    height: 870rem;
    left: 10%;
    top: -90rem;

    @media (min-width: $screen-l) {
        top: -160rem;
        height: 1210rem;
    }

    @media (max-width: $screen-m) {
        top: 3rem;
        height: 463rem;
    }

    @media (max-width: $screen-s) {
        width: 100vw;
        height: 450rem;
        position: relative;
        left: 0;
        margin-left: 0;
        top: 0;
    }
}

.hp-about__animation-item {
    background-repeat: no-repeat;
    background-position: center center;
    background-size: auto 100%;
    aspect-ratio: 1050 / 1376;
    position: absolute;
    height: 870rem;
    left: 50%;
    margin-left: -450rem;
    top: 0;
    opacity: 0;

    @media (min-width: $screen-l) {
        height: 1210rem;
        margin-left: -620rem;
    }

    @media (max-width: $screen-m) {
        height: 463rem;
        margin-left: -225rem;
    }

    @media (max-width: $screen-s) {
        height: 423rem;
        margin-left: -170rem;
    }

    @for $i from 1 through 20 {
        
        &._item-#{$i} {
            background-image: url('../img/racket/racket_#{$i}.webp');
        }
    }

    &._is-visible {
        opacity: 1;
    }
}

.hp-about__animation-ball-1 {
    position: absolute;
    bottom: -28rem;
    left: 50%;
    height: 117rem;
    aspect-ratio: 2.34;
    background: url('../img/racket_ball_1.webp') no-repeat left top;
    background-size: auto 100%;
    margin-left: -80rem;

    @media (min-width: $screen-l) {
        margin-left: -121rem;
        bottom: -30rem;
        height: 170rem;
    }

    @media (max-width: $screen-m) {
        bottom: -17rem;
        height: 63rem;
        margin-left: -29rem;
    }

    @media (max-width: $screen-s) {
        bottom: 13rem;
        height: 58rem;
        margin-left: 7rem;
    }
}

.hp-about__animation-ball-2,
.hp-about__animation-ball-3 {
    position: absolute;
    aspect-ratio: 1;
    width: 110rem;
    left: 0;
    top: 60%;
    margin-left: -70rem;
    z-index: 3;

    @media (min-width: $screen-l) {
        width: 153rem;
        margin-left: -110rem;
    }

    @media (max-width: $screen-m) {
        width: 60rem;
        margin-left: 17rem;
    }

    @media (max-width: $screen-s) {
        width: 54rem;
    }

    > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url('../img/racket_ball_2.webp') no-repeat center center;
        background-size: 100% auto;
        z-index: 2;
    }

    &:after {
        content: '';
        display: block;
        width: 90%;
        height: 90%;
        position: absolute;
        z-index: 1;
        left: 5%;
        top: 50%;
        border-radius: 50%;
        background: #000;
        opacity: 0.12;
        filter: blur(20rem);
        will-change: transform;

        @media (max-width: $screen-m) {
            filter: blur(10rem);
        }
    }
}

.hp-about__animation-ball-3 {
    left: 86%;
    top: 70%;

    @media (min-width: $screen-l) {
        left: 93%;
    }

    @media (max-width: $screen-m) {
        left: 90%;
    }

    @media (max-width: $screen-s) {
        left: 76%;
    }

    > div {
        transform: rotate(45deg);
    }
}
