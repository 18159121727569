.video {
    width: 100%;
}

.video__inner {
    width: 100%;
    aspect-ratio: 16 / 9;
    background: #000;
    display: flex;
    justify-content: center;
    position: relative;
    overflow: hidden;
    border-radius: 12rem;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;

    iframe {
        display: block;
        max-height: 630rem;
        max-width: 100%;
        height: unset !important;
        width: 100% !important;
        position: relative;
        z-index: 2;

        @media (min-width: $screen-l) {
            max-height: 900rem;
        }

        @media (max-width: $screen-m) {
            max-height: 530rem;
        }

        @media (max-width: $screen-s) {
            max-height: 150vw;
        }
    }
}

.video__preview {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    will-change: transform;
    transition: transform 1s;

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    html.desktop .video__play:hover ~ & {
        transform: scale(1.06);
    }
}

.video__play {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 2;
    background: rgba(0,0,0,0.5);

    .video._is-active & {
        display: none;
    }
}

.video__play-btn {
    display: block;
    width: 38rem;
    height: 38rem;
    background: url('../img/play.svg') no-repeat center center;
    background-size: 100% 100%;
    padding: 0;
    border: 0;
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    margin: -19rem 0 0 -19rem;
    overflow: hidden;
    transition: opacity 0.2s ease;

    span {
        @include v-hidden();
    }
}

.video__caption {
    margin-top: 16rem;
    color: var(--color-grey);
    font-variation-settings: 'wght' 300;

    @media (max-width: $screen-s) {
        margin-top: 12rem;
    }
}
