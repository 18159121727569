.about-geo {
    position: relative;
    padding: 60rem 0 50rem 0;
    background: #fff;

    @media (max-width: $screen-m) {
        padding: 80rem 0;
    }

    @media (max-width: $screen-s) {
        padding: 60rem 0;
    }
}

.about-geo__inner {
    @include wrap();
    min-height: 770rem;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 2;
    box-sizing: border-box;
    pointer-events: none;

    @media (min-width: $screen-l) {
        min-height: 1060rem;
    }

    @media (max-width: $screen-m) {
        min-height: 700rem;
        justify-content: space-between;
    }
}

.about-geo__text {
    width: 33%;
    pointer-events: auto;

    @media (max-width: $screen-m) {
        width: 50%;
    }

    @media (max-width: $screen-s) {
        width: 100%;
    }

    h2 {
        margin-top: 0;
        letter-spacing: -0.02em;

        @media (max-width: $screen-m) {
            width: 120%;
        }

        @media (max-width: $screen-s) {
            width: 100%;
        }
    }
}

.about-geo__info {
    width: 33%;
    background: #fff;
    border-radius: 20rem;
    box-sizing: border-box;
    padding: 40rem 50rem 40rem 40rem;
    height: auto;
    align-self: flex-end;
    margin-top: 120rem;
    pointer-events: auto;
    line-height: 1.4;
    font-variation-settings: 'wght' 300;

    @media (min-width: $screen-l) {
        margin-top: 380rem;
    }

    @media (max-width: $screen-m) {
        width: 50%;
        margin-top: 360rem;
        padding: 20rem;
    }

    @media (max-width: $screen-s) {
        margin-top: 520rem;
        width: 100%;
    }

    p {
        margin: 0;
    }

    em {
        font-style: normal;
        font-variation-settings: 'wght' 600;
        color: var(--color-orange);
    }
}

.about-geo__map {
    background: linear-gradient(90deg, rgba(246, 211, 101, 0.30) 0%, rgba(253, 160, 133, 0.30) 100%);
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    @media (max-width: $screen-s) {
        overflow-y: hidden;
        overflow-x: auto;
        

        &::-webkit-scrollbar {
            height: 0;
        }
    }
}

.about-geo__map-inner {
    aspect-ratio: 2.1678;
    height: 1160rem;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-46vw) translateY(-53%);
    overflow: hidden;

    @media (min-width: $screen-l) {
        height: 1520rem;
        transform: translateX(-46vw) translateY(-51%);
    }

    @media (min-width: $screen-xl) {
        transform: translateX(-900rem) translateY(-50%);
    }

    @media (max-width: $screen-m) {
        transform: translateX(-47vw) translateY(-66%);
    }

    @media (max-width: $screen-s) {
        transform: translateX(-49vw) translateY(-70%);
        aspect-ratio: 0.65;
    }

    img {
        display: block;
        height: 100%;
    }
}
