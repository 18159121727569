.news-slider {
    margin-top: 40rem;

    @media (max-width: $screen-m) {
        margin-top: 20rem;
    }

    .swiper {
        overflow: visible;
    }

    .swiper-slide {
        height: auto;
    }
}

.news-slider__inner {

    @media (max-width: $screen-m) {
        overflow: visible;
    }
}

.news-slider__bottom {
    display: flex;
    gap: 60rem;
    justify-content: flex-end;
    margin-top: 10rem;

    @media (max-width: $screen-m) {
        gap: 40rem;
    }

    @media (max-width: $screen-s) {
        gap: 10rem;
        margin-top: 0;
    }
}

.news-slider__navigation {
    width: 96rem;
    display: flex;
    user-select: none;
}

.news-slider__controls {
    width: 200rem;
    display: flex;
    flex-shrink: 0;
    gap: 20rem;
    justify-content: flex-end;

    @media (max-width: $screen-m) {
        width: 180rem;
    }

    @media (max-width: $screen-s) {
        width: 96rem;
    }
}

.news-slider__prev,
.news-slider__next {
    display: block;
    width: 48rem;
    height: 48rem;
    position: relative;
    overflow: hidden;
    border: 0;
    padding: 0;
    background: none;
    transition: color 0.2s ease;
    color: var(--color-orange);

    html.desktop &:hover {
        color: var(--color-dark);
    }

    &:disabled {
        opacity: 1;
        color: #C6C7CB;
        cursor: default;
    }

    &.swiper-button-lock {
        display: none;
    }
    
    span {
        @include v-hidden();
    }

    svg {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
    }
}
