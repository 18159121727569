.about-s3 {
    height: calc(100svh - 76rem);
    position: relative;

    @media (max-width: $screen-m) {
        height: auto;
    }

    .about-screen__inner {
        justify-content: flex-end;

        @media (max-width: $screen-m) {
            padding-bottom: 80rem;
            display: block;
        }

        @media (max-width: $screen-s) {
            padding-bottom: 60rem;
        }
    }
}

.about-s3__top {
    margin-top: 60rem;

    @media (min-width: $screen-l) {
        margin-top: 100rem;
    }

    @media (max-width: $screen-m) {
        margin-top: 80rem;
    }

    @media (max-width: $screen-s) {
        margin-top: 60rem;
    }
}

.about-s3__bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media (max-width: $screen-s) {
        display: block;
    }
}

.about-s3__text {
    width: 33%;
    margin-top: auto;
    color: var(--color-dark);

    h2, .h2 {
        line-height: 1.1;
        margin-right: -40%;
        letter-spacing: -0.01em;

        @media (max-width: $screen-m) {
            margin-right: -20%;
        }

        @media (max-width: $screen-s) {
            margin-right: 0;
        }
    }

    @media (max-width: $screen-m) {
        width: 60%;
    }

    @media (max-width: $screen-s) {
        width: 100%;
    }
}

.about-s3__circles {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 16rem;
    position: absolute;
    right: 0;
    top: 50%;
    --circle-width: 280rem;
    --gap: 16rem;
    margin-top: calc(var(--circle-width) * -1 - var(--gap) / 2);
    width: calc(var(--circle-width) * 3 + var(--gap) * 2);

    @media (min-width: $screen-l) {
        --circle-width: 396rem;
    }

    @media (max-width: $screen-m) {
        position: relative;
        top: unset;
        margin-top: 20rem;
        --circle-width: 100%;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 12rem;
        width: 100%;
    }
    
    @media (max-width: $screen-s) {
        grid-template-columns: 1fr;
        --circle-width: 75%;
        gap: 0;
    }
}

.about-s3__circle {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--circle-width);
    aspect-ratio: 1;
    border-radius: 50%;
    overflow: hidden;
    background: linear-gradient(109deg, rgba(204, 193, 183, 0.30) 0%, rgba(123, 159, 178, 0.30) 0.01%, rgba(224, 212, 196, 0.30) 93.08%);
    flex-shrink: 0;

    @media (max-width: $screen-s) {
        grid-column-start: unset !important;
        grid-row-start: unset !important;

        &:nth-child(2),
        &:nth-child(4) {
            justify-self: end;
        }
    }

    &:nth-child(1) {
        grid-column-start: 1;
        grid-row-start: 1;
    }

    &:nth-child(2) {
        grid-column-start: 2;
        grid-row-start: 1;
    }

    &:nth-child(3) {
        grid-column-start: 2;
        grid-row-start: 2;
    }

    &:nth-child(4) {
        grid-column-start: 3;
        grid-row-start: 2;
    }

    img {
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.about-s3__circle-text {
    width: 75%;
    text-align: center;
    font-size: 14rem;
    line-height: 1.5;

    @media (min-width: $screen-l) {
        font-size: 20rem;
    }

    @media (max-width: $screen-m) {
        font-size: 11rem;
    }
}

.about-s3__ball-1,
.about-s3__ball-2 {
    position: absolute;
    aspect-ratio: 1;
    width: 64rem;
    left: 6%;
    top: 30%;
    z-index: 3;

    @media (max-width: $screen-m) {
        display: none;
    }

    > div {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: url('../img/ball_small.webp') no-repeat center center;
        background-size: 100% auto;
        z-index: 2;
    }

    &:after {
        content: '';
        display: block;
        width: 90%;
        height: 90%;
        position: absolute;
        z-index: 1;
        left: 5%;
        top: 50%;
        border-radius: 50%;
        background: #000;
        opacity: 0.12;
        filter: blur(10rem);
        will-change: transform;

        @media (max-width: $screen-m) {
            filter: blur(10rem);
        }
    }
}

.about-s3__ball-2 {
    left: 84%;
    top: 15%;

    > div {
        transform: rotate(45deg);
        background-image: url('../img/racket_ball_2.webp');
    }
}
